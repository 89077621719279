import request from '../request';
import eventBus from '../../unit/eventBus'
/* ============================= 模板 ============================= */
//获取模板列表
function getProjectList(data) {
    return request({
        url: '/pm/prj',
        method: 'get',
        params: data
    })
}

//获取模板详情
function getProjectInfo(prj_id, ) {
    return request({
        url: `/pm/prj?project_id=${prj_id}`,
        method: 'get',
    })
}

/* ============================= 项目 ============================= */
//创建项目
function creatProject(data) {
    return request({
        url: '/pm/prj',
        method: 'put',
        data
    })
}

//修改项目收藏状态
function changeProjectStar(project_id, is_star_mark) {
    return request({
        url: `/pm/foucs/${project_id}`,
        method: 'post',
        data: {
            is_star_mark: is_star_mark.toString()
        }
    })
}

//修改项目名称
function changeProjectName(project_id, project_name) {
    return request({
        url: `/pm/prj/${project_id}`,
        method: 'post',
        data: {
            project_name: project_name
        }
    })
}

//修改项目成员
function changeProjectUsers(project_id, user_list) {
    return request({
        url: `/pm/mbm/${project_id}`,
        method: 'post',
        data: {
            user_list
        }
    })
}

//生成邀请链接
function creatInviteLinks(project_id) {
    return request({
        url: `/pm/mbm_invite_links/${project_id}`,
        method: 'post',
    })
}

//邀请链接点击
function addInvite(data) {
    return request({
        url: `/pm/mbm?data=${data}`,
        method: 'put',
    })
}

//删除项目
function deleteProject(project_id) {
    return request({
        url: `/pm/${project_id}`,
        method: 'delete',
    })
}

//修改项目-修改status
function changeProjectStatus(project_id, code) {
    return request({
        url: `/pm/prj/${project_id}`,
        method: 'post',
        data: {
            status: code
        }
    })
}

//项目首次尽调进度
function getProjectProgress(id) {
    return request({
        url: `pm/info/overview/process/${id}`,
        method: 'get',
    })
}

//生成项目名称
function getProjectName(scene_name, company_name) {
    return request({
        url: `/common/prj_name?scene_name=${scene_name}&company_name=${company_name}`,
        method: 'get',
    })
}

//生成项目名称
function getProjectRelevant(company_id, project_id) {
    return request({
        url: `/pm/prj_relevant/${company_id}?project_id=${project_id}`,
        method: 'get',
    })
}

//生成项目名称
function getProjectStatus() {
    return request({
        url: `/pm/prj/prj_status`,
        method: 'get',
    })
}

/* ============================= 通用(获取枚举) ============================= */
//根据公司名称模糊查询公司id
function getCompanyInfo(company_name) {
    return request({
        url: `/pm/info/data/company/${encodeURIComponent(company_name)}`,
        method: 'get',
    })
}

//根据名称模糊查询公司和自然人
function getCompanyAndPerson(name, project_id, only_search_person) {
    return request({
        url: `/pm/info/data/company_person_name/${encodeURIComponent(name)}`,
        method: 'get',
        params: {
            project_id,
            only_search_person
        }
    })
}

//获取自然人在某个公司的职位
function getPersonRole(project_id, name, company_id) {
    return request({
        url: `/pm/info/data/person_role`,
        method: 'get',
        params: {
            project_id,
            name,
            company_id
        }
    })
}

//获取菜单功能树
function getMenuTreeData(code) {
    return request({
        url: `/common/functions?scene_code=${code}`,
        method: 'get',
    })
}

//获取项目场景接口
function getScenes() {
    return request({
        url: '/common/scenes',
        method: 'get',
    })
}

//获取企业用户成员接口
function getUsers() {
    return request({
        url: '/sys/company/user/',
        method: 'get',
    })
}

//获取共用字典接口
function getDicCodeInfo(code) {
    return request({
        url: `sys/dic/item/?dic_code=${code}`,
        method: 'get',
    })
}

//获取 options
function getOptions(url) {
    return request({
        url: `${url}`,
        method: 'get',
    })
}

//通用数据接口统计
function getOptionsNumber(project_id, params) {
    return request({
        url: `pm/info/data/statistics/${project_id}`,
        method: 'get',
        params
    })
}

//通过自然人名称和公司id 获取自然人id
function getPersonId(id, params) {
    return request({
        url: `pm/info/basic/get_person_id/${id}`,
        method: 'get',
        params
    })
}

/* ============================= 批注 ============================= */
//数据批注
function setRemark(project_id, data) {
    return request({
        url: `/pm/info/data/remark/${project_id}`,
        method: 'put',
        data
    })
}

//删除批注
function deleteRemark(remark_id, project_id, data_string) {
    return request({
        url: `/pm/info/data/remark/${remark_id}`,
        method: 'delete',
        data: {
            project_id,
            data_string
        }
    })
}

//关注列表中的数据批注接口
function setFocusRemark(project_id, data) {
    return request({
        url: `/pm/info/data/focus_list/remark/${project_id}`,
        method: 'put',
        data
    })
}

//特殊事项整体批注查询
function getSpecialFocusRemark(project_id, params) {
    return request({
        url: `/pm/info/data/remark/${project_id}`,
        method: 'get',
        params
    })
}

//股权穿透节点的批注查询
function getNodeRemark(project_id, data_string) {
    return request({
        url: `/pm/info/basic/remark/${project_id}`,
        method: 'get',
        params: {
            data_string
        }
    })
}

//获取自定义标签列表
function getCustomTags(code) {
    return request({
        url: `/pm/info/data/custom_tags`,
        method: 'get',
    })
}

/* ============================= 关注 ============================= */
//关注事项
function getFocus(data) {
    return request({
        url: `/pm/info/data/focus`,
        method: 'get',
        params: data
    })
}

//关闭关注事项
function closeFocus(id, project_id) {
    return request({
        url: `pm/info/data/focus/${id}`,
        method: 'post',
        data: {
            project_id
        }
    })
}

//获取关注的详情
function getFocusInfo(id, project_id) {
    return request({
        url: `/pm/info/data/focus/${id}`,
        method: 'get',
        params: {
            project_id
        }
    })
}

//一键清除推荐关注
function clearSysFocus(project_id) {
    return request({
        url: `/pm/info/data/sys_focus/${project_id}`,
        method: 'delete',
    })
}

/* ============================= 订阅 ============================= */
//订阅消息
function getSub(data) {
    return request({
        url: `/pm/info/overview/sub`,
        method: 'get',
        params: data
    })
}

//订阅消息 第二层
function getSubDataInfo(data) {
    return request({
        url: `/pm/info/overview/sub_data_info`,
        method: 'get',
        params: data
    })
}

//我的订阅
function getMySub(data) {
    return request({
        url: `/pm/info/overview/my_sub`,
        method: 'get',
        params: data
    })
}

//订阅详情
function getSubInfo(sub_id) {
    return request({
        url: `/pm/info/overview/sub/${sub_id}`,
        method: 'get',
    })
}

//添加订阅
function setSub(project_id, data) {
    return request({
        url: `/pm/info/overview/sub/${project_id}`,
        method: 'put',
        params: data
    })
}

//删除订阅
function deleteSub(sub_id, project_id) {
    return request({
        url: `/pm/info/overview/sub/${sub_id}`,
        method: 'delete',
        data: {
            project_id
        }
    })
}

//获取订阅内容
function getSubContent(project_id) {
    return request({
        url: `/pm/info/overview/sub_content?project_id=${project_id}`,
        method: 'get',
    })
}

/* ============================= 数据相关 ============================= */
//数据修订
function reviseHandle(project_id, data) {
    return request({
        url: `pm/info/data/revise/${project_id}`,
        method: 'put',
        data
    })
}

//删除某一条数据
function deleteTableItem(id, params) {
    return request({
        url: `/pm/info/data/table/${id}`,
        method: 'delete',
        data: params
    })
}

//获取数据
function getDataInfo(type, project_id, params) {
    return request({
        url: `/pm/info/data/table/`,
        method: 'post',
        data: {
            project_id,
            name: type,
            ...params
        }
    })
}

//通用数据接信息下载
function exportData(type, project_id, params) {
    return request({
        url: `/pm/info/data/export`,
        method: 'get',
        responseType: 'arraybuffer',
        params: {
            project_id,
            name: type,
            ...params
        }
    })
}


//通用数据接口新增修改表格样式接口
function getTableStyle(name, project_id, is_bid) {
    return request({
        url: `/pm/info/data/table_style?name=${name}`,
        method: 'get',
        params: {
            project_id,
            is_bid
        }
    })
}

//通用数据接口新增修改表格样式接口
function setTableItem(data) {
    return request({
        url: `/pm/info/data/table`,
        method: 'put',
        data
    })
}

//通用数据接口 获取单数据详情
function getTableDetail(id, name, project_id, is_bid, is_suspect_record) {
    return request({
        url: `/pm/info/data/data_table_detail/${id}?name=${name}&project_id=${project_id}`,
        method: 'get',
        params: {
            is_bid,
            is_suspect_record
        }
    })
}

//上传图片
function uploadImages(type, data) {
    return request({
        headers: {
            'Content-Type': 'multipart/form-data;'
        },
        url: `common/upload_images?type=${type}`,
        method: 'put',
        data
    })
}

//上传文件
function uploadFiles(type, data) {
    return request({
        headers: {
            'Content-Type': 'multipart/form-data;'
        },
        url: `common/upload_file?type=${type}`,
        method: 'put',
        data
    })
}

//上传文件或者图片
function uploadAllFiles(type, data) {
    return request({
        headers: {
            'Content-Type': 'multipart/form-data;'
        },
        url: `common/upload_all?type=${type}`,
        method: 'put',
        data
    })
}


//查看法人信息
function getChegetLegalPersonInfo(id, companyId, is_branch) {
    return request({
        url: `/pm/info/data/get_legal_person_list?company_id=${companyId}&project_id=${id}&is_branch=${is_branch || false}`,
        method: 'get',
    })
}

//查看自然人信息
function getNaturalPersonInfo(id, data) {
    return request({
        url: `/pm/info/data/get_natural_person_list`,
        method: 'get',
        params: {
            project_id: id,
            ...data
        }
    })
}

//获取分组筛选条件
function getFiledGroupOptionsList(id, data) {
    return request({
        url: `pm/info/data/get_filed_group_list`,
        method: 'get',
        params: {
            project_id: id,
            ...data
        }
    })
}

/* ============================= 工具箱 ============================= */
//获取工具箱
function getTool() {
    return request({
        url: `/tool/toolbox`,
        method: 'get',
    })
}

//收藏网站
function setFocusWebsite(id, status) {
    return request({
        url: `/tool/focus_website/${id}?status=${status}`,
        method: 'put',
    })
}

/* ============================= 报告 底稿 ============================= */
//获取报告配置
function getReportSettings(id) {
    return request({
        url: `/pm/info/rpt/report_settings?project_id=${id}`,
        method: 'get',
    })
}

//生成底稿
function creatGeneration(data) {
    return request({
        url: `/pm/info/rpt/generation/`,
        method: 'put',
        data
    })
}

//查询底稿列表
function getGenerationList(params) {
    return request({
        url: `/pm/info/rpt/generation`,
        method: 'get',
        params
    })
}

//删除底稿
function deleteGeneration(params) {
    return request({
        url: `/pm/info/rpt/report`,
        method: 'delete',
        data: params
    })
}

//下载底稿
function downloadGeneration(params) {
    return request({
        url: `/pm/info/rpt/download`,
        method: 'get',
        params
    })
}

//重新生成底稿
function retryGeneration(data) {
    return request({
        url: `/pm/info/rpt/report`,
        method: 'post',
        data,
    })
}

//查询底稿进度
function getReportProcess(id, ids, manu_ids, company_id) {
    return request({
        url: `/pm/info/rpt/report_process?project_id=${id}&report_ids=${ids}&manu_ids=${manu_ids}${company_id ? ('&company_id='+company_id) : ''}`,
        method: 'get',
    })
}

//查询底稿报告类型
function getReportTypes() {
    return request({
        url: `/pm/info/rpt/manu_pic`,
        method: 'get',
    })
}

//根据文件地址获取文件流
function getFileUrl(path, project_id) {
    eventBus.emit('downloadFileChange', '新增', path, 0)
    return request({
        url: `/pm/info/rpt/download_file?path=${path}&key=${Math.random()}&project_id=${project_id}`,
        method: 'get',
        responseType: "arraybuffer",
        onDownloadProgress: function (progressEvent) { //axios封装的原生获取下载进度的事件，该回调参数progressEvent中包含下载文件的总进度以及当前进度
            if (progressEvent.lengthComputable) {

                if ((progressEvent.loaded / progressEvent.total) * 100 === 100) {
                    eventBus.emit('downloadFileChange', '删除', path, 0)
                } else {
                    eventBus.emit('downloadFileChange', '修改', path, parseInt((progressEvent.loaded / progressEvent.total) * 100))
                }
                // console.log('下载中...' + ((progressEvent.loaded / progressEvent.total)* 100).toFixed(2) + '%');
                //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
                //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
                //downLoadProgress.value = progressEvent.loaded / progressEvent.total * 100 //实时获取最新下载进度
            }
        },
    })
}

/* ============================= 股权信息 ============================= */
//获取股权信息
function getPenetrationInfo(id) {
    return request({
        url: `/pm/info/basic/penetration2/${id}`,
        method: 'get',
    })
}

//编辑股权节点
function editPenetrationNode(id, data) {
    return request({
        url: `/pm/info/basic/penetration3/${id}`,
        method: 'post',
        data
    })
}

//新增股权节点
function setPenetrationNode(id, data) {
    return request({
        url: `/pm/info/basic/penetration/${id}`,
        method: 'put',
        data
    })
}

//删除 股权节点
function deletePenetrationNode(id, data) {
    return request({
        url: `/pm/info/basic/penetration/${id}`,
        method: 'delete',
        data
    })
}

//股权 保存修改
function editPenetrationNodes(id, data) {
    return request({
        url: `/pm/info/basic/re_penetration/${id}`,
        method: 'post',
        data
    })
}

// 股权保存自然人
function editPenetrationPersonNodes(id, params) {
    return request({
        url: `pm/info/basic/penetration_person_id/${id}`,
        method: 'get',
        params
    })
}

//股权比例文件下载
function downLoadBasicExcel(project_id, params) {
    return request({
        url: `pm/info/basic/download_excel/${project_id}`,
        method: 'get',
        params
    })
}

//主要人员持股比例个人简介查询
function getPersonInfo(params) {
    return request({
        url: `pm/info/data/person_info`,
        method: 'get',
        params
    })
}

//股权穿透默认核查规则
function getPenetrationDefaultRules() {
    return request({
        url: `pm/info/basic/penetration/default_rule`,
        method: 'get',
    })
}

//一致行动人组查询
function getConcertedActionPersonGroup(id) {
    return request({
        url: `pm/info/basic/group/${id}`,
        method: 'get',
    })
}

//一致行动人组 增删改
function handleConcertedActionPersonGroup(id, group_info, desc_info, isEdit) {
    return request({
        url: `pm/info/basic/group/${id}`,
        method: 'post',
        data: {
            group_info,
            desc_info,
            isEdit
        }
    })
}

/* ============================= 案件 ============================= */
//获取项目案由和涉案主体
function getCaseReason(project_id) {
    return request({
        url: `pm/info/case/case_reason_and_parties/${project_id}`,
        method: 'get',
    })
}

// 获取司法案件首页查询
function getCaseHomePageInfo(project_id, data) {
    return request({
        url: `/pm/info/case/${project_id}`,
        method: 'post',
        data
    })
}

// 获取司法详情
function getCaseDetailInfo(project_id, case_id) {
    return request({
        url: `/pm/info/case/detail/${project_id}`,
        method: 'get',
        params: {
            case_id
        }
    })
}

/* ============================= 关联方 ============================= */
//疑似关联方提交核查
function setCheck(id, suspect_info, suspect_rule) {
    return request({
        url: `pm/info/suspect/${id}`,
        method: 'put',
        data: {
            suspect_info,
            suspect_rule
        }
    })
}

//疑似关联方查询
function getCheck(id) {
    return request({
        url: `pm/info/suspect/${id}`,
        method: 'get',
    })
}

//核查是否为关联方
function checkRelatedParties(id, data) {
    return request({
        url: `/pm/info/suspect/exists_related/${id}`,
        method: 'get',
        params: {
            data
        }
    })
}

//疑似关联方历史列表查询
function getHistoryCheck(id) {
    return request({
        url: `pm/info/suspect/history/${id}`,
        method: 'get',
    })
}

//疑似关联方列表查询
function getCheckList(id, data) {
    return request({
        url: `pm/info/suspect/${id}`,
        method: 'post',
        data
    })
}

//疑似关联方关系图谱查询
function getChartsData(project_id, id) {
    return request({
        url: `pm/info/suspect/graph/${project_id}?suspect_id=${id}`,
        method: 'get',
    })
}

//删除 关联方
function deleteCheck(id, suspect_id) {
    return request({
        url: `pm/info/suspect/${id}`,
        method: 'delete',
        data: {
            suspect_id,
        }
    })
}

//修改 关联方 核查状态  CDD_SUSPECT_STATUS_QUIT关闭/CDD_SUSPECT_STATUS_WAIT重启
function setCheckStatus(id, suspect_id, suspect_status) {
    return request({
        url: `pm/info/suspect/change_status/${id}`,
        method: 'post',
        data: {
            suspect_id,
            suspect_status
        }
    })
}

//下载异常疑似关联方列表
function downloadCheckFailFile(id, suspect_fail_info) {
    return request({
        url: `pm/info/suspect/download_fail/${id}`,
        method: 'put',
        data: {
            suspect_fail_info
        }
    })
}

//疑似关联方文件上传解析
function uploadCheckFiles(id, data) {
    return request({
        headers: {
            'Content-Type': 'multipart/form-data;'
        },
        url: `pm/info/suspect/upload/${id}`,
        method: 'put',
        data
    })
}

//获取关联方重选状态
function getRecalcStatus(id) {
    return request({
        url: `pm/info/related/recalc_status/${id}`,
        method: 'get',
    })
}

//关联方文件上传解析
function uploadRelatedFiles(id, data) {
    return request({
        headers: {
            'Content-Type': 'multipart/form-data;'
        },
        url: `pm/info/related/upload/${id}`,
        method: 'put',
        data
    })
}

//下载异常关联方
function downloadRelatedFailFile(id, related_fail_info) {
    return request({
        url: `pm/info/related/download_fail/${id}`,
        method: 'put',
        data: {
            related_fail_info
        }
    })
}

//批量上传关联方
function upRelatedFunc(id, related_info) {
    return request({
        url: `pm/info/related/${id}`,
        method: 'put',
        data: {
            related_info
        }
    })
}

//下载关联方图谱
function downloadSuspect(id, company_name, check_time) {
    return request({
        url: `pm/info/suspect/download/${id}`,
        method: 'get',
        params: {
            company_name,
            check_time
        }
    })
}

//疑似关联方默认核查规则
function getSuspectDefaultRules() {
    return request({
        url: `pm/info/suspect/default_rule`,
        method: 'get',
    })
}

//查询疑似关联方介绍列表图片
function getSuspectPic() {
    return request({
        url: `/pm/info/suspect/suspect_pic`,
        method: 'get',
    })
}

//根据record_id获取疑似核查id
function getSuspectId(id) {
    return request({
        url: `/pm/info/suspect/suspect_id?id=${id}`,
        method: 'get',
    })
}

//下载 关联方核查对象列表 工商数据
function getVerifyObjectFile(suspect_id, company_name) {
    return request({
        url: `/pm/info/suspect/download/verify_object/${suspect_id}?company_name=${company_name}`,
        method: 'get',
    })
}

//获取疑似关联方 不同事件 公司列表
function getEventSuspect(id, name) {
    return request({
        url: `/pm/info/suspect/get_event_suspect/${id}?name=${name}`,
        method: 'get',
    })
}

//获取疑似关联方 不同事件
function getEventSuspectType(id) {
    return request({
        url: `/pm/info/suspect/event_suspect_type/${id}`,
        method: 'get',
    })
}

/* ============================= 通知 ============================= */
//获取全部通知
function getNotices(page, page_size) {
    return request({
        url: `common/get_notice_list`,
        method: 'get',
        params: {
            page,
            page_size
        }
    })
}

//获取未读通知
function getUnreadNotices(page, page_size) {
    return request({
        url: `common/get_unread_notice`,
        method: 'get',
        params: {
            page,
            page_size
        }
    })
}

//消息修改已读状态接口
function setReadNotices() {
    return request({
        url: `common/put_notice_flag?spread=all`,
        method: 'put',
    })
}

//删除系统消息
function deleteNotices() {
    return request({
        url: `common/delete_notice?spread=read`,
        method: 'delete',
    })
}

/* ============================= 权限 ============================= */
//获取用户系统功能点
function getUserPermission() {
    return request({
        url: `/sys/permission/func/list/`,
        method: 'get',
    })
}

/* ============================= 日志 ============================= */
//获取操作日志
function getOptLog(params) {
    return request({
        url: `/log/opt_log`,
        method: 'get',
        params
    })
}

//添加一条操作日志
function setOptLog(id, params) {
    return request({
        url: `/log/opt_log/${id}`,
        method: 'get',
        params
    })
}

//操作日志查询枚举接口
function getLogEnums() {
    return request({
        url: `/log/log_enum_map`,
        method: 'get',
    })
}

/* ============================= 招投标 ============================= */
//获取 招投标分析 数据
function getBiddingDevelopmentAnalysis(params) {
    return request({
        url: `/pm/info/data/bidding_development_analysis`,
        method: 'get',
        params
    })
}

//下载招投标分析数据文件
function downloadBiddingAnalysisFile(data) {
    return request({
        url: `/pm/info/data/bidding_analysis_export`,
        method: 'post',
        responseType: 'arraybuffer',
        data
    })
}

//获取 招投标竞争对手分析列表数据 
function getBiddingCompetitivenessAnalysis(params) {
    return request({
        url: `/pm/info/data/bidding_competitiveness_analysis`,
        method: 'get',
        params
    })
}

//增加修改 招投标竞争对手
function addBiddingCompetitiveness(params) {
    return request({
        url: `/pm/info/data/bidding_competitiveness`,
        method: 'put',
        data: params
    })
}

//删除 招投标竞争对手
function deleteBiddingCompetitiveness(id, project_id) {
    return request({
        url: `/pm/info/data/bidding_competitiveness/${id}?project_id=${project_id}`,
        method: 'delete',
    })
}

//删除 所有招投标竞争对手
function deleteBiddingCompetitivenessAll(project_id) {
    return request({
        url: `/pm/info/data/batch_delete_bidding_competitiveness/${project_id}`,
        method: 'delete',
    })
}

//招投标竞争力中标列表
function getCompetitiveHitData(params) {
    return request({
        url: `/pm/info/data/bidding_competitiveness_data`,
        method: 'post',
        data: params
    })
}

//竞争对手文件上传解析
function checkUploadBiddingCompetitiveness(id, data) {
    return request({
        headers: {
            'Content-Type': 'multipart/form-data;'
        },
        url: `pm/info/data/check_upload_bidding_competitiveness/${id}`,
        method: 'put',
        data
    })
}

//批量上传竞争对手
function upBiddingCompetitiveness(id, success_data, file_filename) {
    return request({
        url: `pm/info/data/upload_bidding_competitiveness/${id}`,
        method: 'put',
        data: {
            success_data,
            file_filename
        }
    })
}

//获取 竞争对手中标列表 数据状态
function getBiddingCompetitivenessStatus(id) {
    return request({
        url: `pm/info/data/bidding_competitiveness_status/${id}`,
        method: 'get',
    })
}

/* ============================= 获取链接 ============================= */
//获取 试用文档等 相关链接
function getConfigUrl() {
    return request({
        url: `/compose/get_app_config`,
        method: 'get',
    })
}

/* ============================= 舆情 ============================= */
//获取 公告舆情 选项 数量
function getConditionStatistics(id, params) {
    return request({
        url: `/pm/info/data/query_condition_statistics/${id}`,
        method: 'post',
        data: params
    })
}

//获取 公告舆情 选项
function getEventTypeAndSubject(id) {
    return request({
        url: `/pm/info/data/get_event_type_and_subject/${id}`,
        method: 'get',
    })
}

/* 财务分析 */

//获取 经营分析票据详情 数据
function getBillDetailAnalysis(id, company_id) {
    return request({
        url: `/pm/info/data/bill_detail_analysis/${id}?company_id=${company_id}`,
        method: 'get',
    })
}

//获取 经营分析销售及客户分析 数据
function getBusinessSaleAnalysis(id, company_id) {
    return request({
        url: `/pm/info/data/business_sale_analysis/${id}?company_id=${company_id}`,
        method: 'get',
    })
}

//获取 采购支出及供应商分析 数据
function getBusinessBuyAnalysis(id, company_id) {
    return request({
        url: `/pm/info/data/business_buy_analysis/${id}?company_id=${company_id}`,
        method: 'get',
    })
}


//数据枚举通用接口
function getDataSelectEnum(id, params) {
    return request({
        url: `pm/info/data/data_select_enum/${id}`,
        method: 'get',
        params
    })
}


//获取采集完成公司列表
function getCollectionCompanyList(id) {
    return request({
        url: `pm/info/data/analysis_company/${id}`,
        method: 'get',
    })
}

//获取 财税税务分析-总览
function getTaxSummaryAnalysis(id, company_id) {
    return request({
        url: `pm/info/data/tax_summary_analysis/${id}?company_id=${company_id}`,
        method: 'get',
    })
}

//获取 财税税务分析-纳税申报
function getTaxReturn(id, company_id) {
    return request({
        url: `pm/info/data/tax_return/${id}?company_id=${company_id}`,
        method: 'get',
    })
}

//获取 财税税务分析-税务风险
function getTaxRisk(id, company_id) {
    return request({
        url: `pm/info/data/tax_risk/${id}?company_id=${company_id}`,
        method: 'get',
    })
}

//财税授权采集生成接口 
function generateAuth(id, params) {
    return request({
        url: `auth/generate_auth/${id}`,
        method: 'put',
        data: params
    })
}

//查看财税授权进程
function getAuthorizationProcess(id, params) {
    return request({
        url: `auth/${id}`,
        method: 'get',
        params
    })
}

//删除财税授权进程
function deleteAuthorizationProcess(project_id, id) {
    return request({
        url: `auth/${project_id}?id=${id}`,
        method: 'delete',
    })
}

//财税授权采集 校验
function checkAuthorize(project_id, params) {
    return request({
        url: `auth/check_authorize/${project_id}`,
        method: 'put',
        data: params
    })
}

//下载财税数据分析数据文件
function downloadFinancialTaxFile(data) {
    return request({
        url: `/pm/info/data/financial_tax_export`,
        method: 'post',
        responseType: 'arraybuffer',
        data
    })
}


//获取更新日志
function getUpdateLogList() {
    return request({
        url: '/sys/log/updates',
        method: 'get',
    })
}

//获取企业列表
function getEnterprise(include_indiv_org) {
    return request({
        url: 'sys/comp/switch/',
        method: 'get',
        params: {
            include_indiv_org
        }
    })
}

//切换 企业
function switchEnterprise(co_id) {
    return request({
        url: 'sys/comp/switch/',
        method: 'put',
        data: {
            co_id
        }
    })
}

//获取授权数量
function getLicenseNums(license_code,project_id) {
    return request({
        url: 'sys/get_license_nums',
        method: 'get',
        params: {
            license_code,
            project_id
        }
    })
}

//获取授权支持不支持
function getLicenseSupport(license_code) {
    return request({
        url: 'sys/get_license_support',
        method: 'get',
        params: {
            license_code
        }
    })
}

//获取 是否授权
function getLicenseValid() {
    return request({
        url: 'sys/get_license_valid',
        method: 'get',
    })
}

//获取管理员
function getAdmins() {
    return request({
        url: 'sys/get_com_user_administrator',
        method: 'get',
    })
}

//修改公司名称
function editCompName(data) {
    return request({
        url: 'sys/comp/name',
        method: 'put',
        data
    })
}

//获取订阅方案
function getLicense(prd_code) {
    return request({
        url: `sys/subs_plan/simple_info?prd_code=${prd_code}`,
        method: 'get',
    })
}

/* ============================= 后督 底稿 ============================= */
//获取 后督是否授权
function getBondLicenseValid() {
    return request({
        url: 'sys/get_license_valid',
        method: 'get',
        params: {
            prd_code: 'CDD_BOND'
        }
    })
}

//查询后督底稿报告类型
function getBondReportTypes(params) {
    return request({
        url: `/bond_rpt/manu_pic`,
        method: 'get',
        params
    })
}

//获取后督报告配置
function getBondReportSettings(id) {
    return request({
        url: `/bond_rpt/report_settings/?project_id=${0}&company_id=${id}`,
        method: 'get',
    })
}

//生成后督底稿
function creatBondGeneration(data) {
    return request({
        url: `/bond_rpt/generation/`,
        method: 'put',
        data
    })
}

//查询后督底稿列表
function getBondGenerationList(params) {
    return request({
        url: `/bond_rpt/generation`,
        method: 'get',
        params
    })
}

//查询后督底稿进度
function getBondReportProcess(ids, manu_ids, company_id) {
    return request({
        url: `/bond_rpt/report_process/?project_id=${0}&report_ids=${ids}&manu_ids=${manu_ids}&company_id=${company_id}`,
        method: 'get',
    })
}

//删除底稿
function deleteBondGeneration(params) {
    return request({
        url: `/bond_rpt/report/`,
        method: 'delete',
        data: params
    })
}

//下载底稿
function downloadBondGeneration(params) {
    return request({
        url: `/bond_rpt/download/`,
        method: 'get',
        params
    })
}

//重新生成后督底稿
function retryBondGeneration(data) {
    return request({
        url: `/bond_rpt/report`,
        method: 'post',
        data,
    })
}

export default {
    getProjectList,
    getProjectInfo,
    getMenuTreeData,
    getScenes,
    getUsers,
    getDicCodeInfo,
    creatProject,
    getCompanyInfo,
    changeProjectStar,
    deleteProject,
    changeProjectStatus,
    changeProjectUsers,
    creatInviteLinks,
    getFocus,
    addInvite,
    getSub,
    getSubDataInfo,
    getMySub,
    getDataInfo,
    setRemark,
    deleteRemark,
    closeFocus,
    reviseHandle,
    deleteTableItem,
    getTableStyle,
    setTableItem,
    getTool,
    getFocusInfo,
    getReportSettings,
    getOptions,
    creatGeneration,
    getGenerationList,
    getReportProcess,
    getPenetrationInfo,
    deleteGeneration,
    retryGeneration,
    downloadGeneration,
    getTableDetail,
    setSub,
    getSubInfo,
    deleteSub,
    changeProjectName,
    setPenetrationNode,
    deletePenetrationNode,
    editPenetrationNode,
    editPenetrationNodes,
    uploadImages,
    getProjectProgress,
    getCaseReason,
    getCaseHomePageInfo,
    getCaseDetailInfo,
    getCheck,
    getHistoryCheck,
    setCheck,
    getChegetLegalPersonInfo,
    getNaturalPersonInfo,
    getNotices,
    getUnreadNotices,
    getProjectName,
    setReadNotices,
    deleteNotices,
    uploadFiles,
    getFiledGroupOptionsList,
    getFileUrl,
    uploadCheckFiles,
    uploadRelatedFiles,
    exportData,
    getSubContent,
    setFocusRemark,
    getUserPermission,
    clearSysFocus,
    getProjectRelevant,
    editPenetrationPersonNodes,
    getOptLog,
    setOptLog,
    getLogEnums,
    getOptionsNumber,
    getPersonId,
    downLoadBasicExcel,
    getCompanyAndPerson,
    getPersonRole,
    getRecalcStatus,
    downloadRelatedFailFile,
    upRelatedFunc,
    getSpecialFocusRemark,
    getNodeRemark,
    getProjectStatus,
    checkRelatedParties,
    getPersonInfo,
    downloadSuspect,
    getReportTypes,
    uploadAllFiles,
    getSuspectDefaultRules,
    getPenetrationDefaultRules,
    getCheckList,
    deleteCheck,
    setCheckStatus,
    downloadCheckFailFile,
    getSuspectPic,
    getSuspectId,
    getConfigUrl,
    getBiddingDevelopmentAnalysis,
    downloadBiddingAnalysisFile,
    getBiddingCompetitivenessAnalysis,
    addBiddingCompetitiveness,
    deleteBiddingCompetitiveness,
    getCompetitiveHitData,
    checkUploadBiddingCompetitiveness,
    upBiddingCompetitiveness,
    getBiddingCompetitivenessStatus,
    deleteBiddingCompetitivenessAll,
    getChartsData,
    getVerifyObjectFile,
    getConditionStatistics,
    getBillDetailAnalysis,
    getDataSelectEnum,
    getBusinessSaleAnalysis,
    getBusinessBuyAnalysis,
    getCollectionCompanyList,
    getTaxSummaryAnalysis,
    getTaxReturn,
    getTaxRisk,
    generateAuth,
    getAuthorizationProcess,
    deleteAuthorizationProcess,
    downloadFinancialTaxFile,
    checkAuthorize,
    getEventTypeAndSubject,
    getEventSuspect,
    getEventSuspectType,
    setFocusWebsite,
    getConcertedActionPersonGroup,
    handleConcertedActionPersonGroup,
    getEnterprise,
    getUpdateLogList,
    switchEnterprise,
    getLicenseNums,
    getLicenseValid,
    getLicenseSupport,
    getAdmins,
    getBondLicenseValid,
    getBondReportTypes,
    getBondReportSettings,
    creatBondGeneration,
    getBondGenerationList,
    getBondReportProcess,
    deleteBondGeneration,
    downloadBondGeneration,
    retryBondGeneration,
    editCompName,
    getLicense,
    getCustomTags
}