import request from '../request';
/* ============================= 首页 ============================= */
//获取债券后督发行人及动态
function getIssuersList(params) {
    return request({
        url: 'bond/issuers/my',
        method: 'get',
        params: params
    })
}

//债券后督首页查询列表
function getIssuers(params) {
    return request({
        url: 'bond/issuers/index',
        method: 'get',
        params: params
    })
}

//添加债券公司发行人
function addIssuers(issuer_list) {
    return request({
        url: 'bond/issuers',
        method: 'put',
        data: {
            issuer_list
        }
    })
}

//文件上传解析
function uploadCheckFiles(data) {
    return request({
        headers: {
            'Content-Type': 'multipart/form-data;'
        },
        url: `/bond/check_upload`,
        method: 'put',
        data
    })
}

//删除债券公司发行人
function deleteIssuers(company_id) {
    return request({
        url: `bond/issuers/${company_id}`,
        method: 'delete',
    })
}

//获取共用字典接口
function getDicCodeInfo(code) {
    return request({
        url: `sys/dic/item/?dic_code=${code}`,
        method: 'get',
    })
}

//通用数据接口统计
function getOptionsNumber(id, params) {
    return request({
        url: `bond/statistics/${id}`,
        method: 'get',
        params
    })
}

//获取模板详情
function getProjectInfo(company_id, params) {
    return request({
        url: `/bond/summary/${company_id}`,
        method: 'get',
        params
    })
}

//获取菜单功能树
function getMenuTreeData(code) {
    return request({
        url: `/bond/functions?scene_code=${code}`,
        method: 'get',
    })
}

//获取后督数据枚举
function getBondEnums(params) {
    return request({
        url: `/bond/enums`,
        method: 'get',
        params
    })
}

//获取数据
function getDataInfo(type, company_id, params) {
    return request({
        url: `/bond/table/`,
        method: 'post',
        data: {
            company_id,
            table_name: type,
            ...params
        }
    })
}

//通用数据接信息下载
function exportData(type, company_id, params) {
    return request({
        url: `/bond/table/`,
        method: 'post',
        responseType: 'arraybuffer',
        data: {
            company_id,
            table_name: type,
            is_export: 1,
            ...params
        }
    })
}

//债券后督事件数据通用查询
function getDataFunc(type, company_id, params) {
    return request({
        url: `/bond/data/${company_id}`,
        method: 'get',
        params: {
            event_type: type,
            ...params
        }
    })
}

//债券后督事件数据通用下载
function exportDataFunc(type, company_id, params) {
    return request({
        url: `/bond/data/${company_id}`,
        method: 'get',
        responseType: 'arraybuffer',
        params: {
            event_type: type,
            is_export: 1,
            ...params
        }
    })
}

//获取分组筛选条件
function getFiledGroupOptionsList(id, data) {
    return request({
        url: `/bond/get_filed_group_list`,
        method: 'get',
        params: {
            company_id: id,
            ...data
        }
    })
}

//获取股权信息
function getPenetrationInfo(id) {
    return request({
        url: `/pm/info/basic/penetration3/${id}`,
        method: 'get',
    })
}

//股权比例文件下载
function downLoadBasicExcel(company_id) {
    return request({
        url: `pm/info/basic/download_excel2/${company_id}`,
        method: 'get',
    })
}

//获取债券后督事件枚举
function getEventTypeMapping(event_type) {
    return request({
        url: `/bond/event_type_mapping?event_type=${event_type}`,
        method: 'get',
    })
}

//获取事件主体枚举
function getRpiGroup(company_id, params) {
    return request({
        url: `/bond/data/rpi_group/${company_id}`,
        method: 'get',
        params
    })
}

/* ============================= 订阅 ============================= */
//订阅消息
function getSub(data) {
    return request({
        url: `/bond_sub/sub`,
        method: 'get',
        params: data
    })
}

//订阅消息 第二层
function getSubDataInfo(data) {
    return request({
        url: `/bond_sub/sub_data_info`,
        method: 'get',
        params: data
    })
}

//我的订阅
function getMySub(data) {
    return request({
        url: `/bond_sub/my_sub`,
        method: 'get',
        params: data
    })
}

//订阅详情
function getSubInfo(sub_id) {
    return request({
        url: `/bond_sub/sub/${sub_id}`,
        method: 'get',
    })
}

//添加订阅
function setSub(company_id, data) {
    return request({
        url: `/bond_sub/sub/${company_id}`,
        method: 'put',
        params: data
    })
}

//删除订阅
function deleteSub(sub_id) {
    return request({
        url: `/bond_sub/sub/${sub_id}`,
        method: 'delete',
    })
}

//通用数据接口 获取单数据详情
function getTableDetail(id, event_code, company_id) {
    return request({
        url: `/bond/data/detail/${company_id}?event_code=${event_code}&id=${id}`,
        method: 'get',
    })
}


export default {
    getIssuersList,
    getIssuers,
    addIssuers,
    deleteIssuers,
    getDicCodeInfo,
    getProjectInfo,
    getMenuTreeData,
    getDataInfo,
    getFiledGroupOptionsList,
    getPenetrationInfo,
    downLoadBasicExcel,
    getBondEnums,
    getDataFunc,
    getOptionsNumber,
    uploadCheckFiles,
    getEventTypeMapping,
    getRpiGroup,
    getSub,
    getSubDataInfo,
    getMySub,
    getSubInfo,
    setSub,
    deleteSub,
    getTableDetail,
    exportData,
    exportDataFunc
}