import "./index.scss";
import Scrollbars from "react-custom-scrollbars";
import {
  HistoryOutlined,
  SettingOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import HeaderTitle from "../../components/HeaderTitle";
import {
  Typography,
  Badge,
  Collapse,
  Tag,
  Input,
  DatePicker,
  message,
  Tooltip,
  Divider,
} from "antd";
import { useSize, useThrottleEffect } from "ahooks";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import SubscribeDrawer from "./component/SubscribeDrawer/index";
import MySpin from "../../components/MySpin";
import SubscribeListDrawer from "./component/SubscribeListDrawer";
import projectApi from "../../apis/project";
import bondApi from "../../apis/bond";
import Empty from "../../components/Empty";
import { useGetState, useDebounce } from "ahooks";
import SubscribeSettingDrawer from "./component/SubscribeSettingDrawer";
import { getLicenseValid, guid } from "../../unit/unit";
import infoRouter from "../Company/router/router";
import bondRouter from "../BondPages/router/router";
import MyRangePicker from "../../components/MyRangePicker";
import { useSelector } from "react-redux";
const { RangePicker } = DatePicker;
const { Text, Paragraph } = Typography;
const { Panel } = Collapse;

const GenHeader = ({ date, companyName, goUrl, info, isBond }) => {
  const spanRef = useRef(null);
  const textRef = useRef(null);
  const size = useSize(spanRef);
  const suffixCount = 4;
  let str = companyName;
  const start = str?.slice(0, str?.length - suffixCount)?.trim();
  const suffix = str?.slice(-suffixCount)?.trim();
  const dateOption = {
    sameDay: "[今天] (YYYY-MM-DD)",
    lastDay: "[昨天] (YYYY-MM-DD)",
    lastWeek: "YYYY-MM-DD",
    sameElse: "YYYY-MM-DD",
  };
  const showDate = moment(date).calendar(null, dateOption);
  //点击公司名称跳转
  const textClick = (e) => {
    e.preventDefault();
    if (e && e.stopPropagation) {
      //即为非IE浏览器
      e.stopPropagation();
    } else {
      //IE方式取消事件冒泡
      window.event.cancelBubble = true;
    }
    goUrl && goUrl(isBond ? info?.company_id : info?.project_id);
  };

  const badgeColor = () => {
    if (showDate.indexOf("今天") !== -1) {
      return "#FE545F";
    } else if (showDate.indexOf("昨天") !== -1) {
      return "#6AD7B2";
    } else {
      return "#0068B2";
    }
  };

  //判断字符串是否是中文
  const isChinese = (temp) => {
    var re = /[^\u4E00-\u9FA5]/;
    if (re.test(temp)) return false;
    return true;
  };
  let typeWidth = 0;
  for (let i = 0; i < showDate?.length; i++) {
    if (isChinese(showDate[i])) {
      typeWidth += 14;
    } else {
      typeWidth += 9;
    }
  }
  return (
    <div style={{ display: "flex" }}>
      <Badge color={badgeColor()} style={{ marginRight: "5px" }} />
      <span ref={spanRef} className="headerDate">
        {showDate}
      </span>
      <Paragraph
        ref={textRef}
        onClick={textClick}
        style={{
          width: `calc(100% - ${(typeWidth || 0) + 10}px)`,
          color: "#006AB2",
        }}
        ellipsis={{
          // suffix,
          tooltip: companyName,
        }}
      >
        {companyName}
      </Paragraph>
    </div>
  );
};

const Subscribe = (props) => {
  const { hasHeader, hasSearch, projectId, projectInfo, isBond } = props;
  const size = useSize(document.querySelector("body"));
  const [forceUpdate, setForceUpdate] = useState(true);
  const [activeKey, setActiveKey, getActiveKey] = useGetState(null);
  const [activeKeyInfo, setActiveKeyInfo, getActiveKeyInfo] = useGetState({});

  const [keyword, setKeyword, getKeyword] = useGetState(null);
  const [startDate, setStartDate, getStartDate] = useGetState(null);
  const [endDate, setEndDate, getEndDate] = useGetState(null);

  const [subscribeList, setSubscribeList, getSubscribeList] = useGetState([]);
  const [subscribeId, setSubscribeId] = useState(null);

  const [loading, setLoading] = useState(true);

  const [visible, setVisible] = useState(false);
  const [settingVisible, setSettingVisible] = useState(false);
  const [settingListVisible, setSettingListVisible] = useState(false);
  const debouncedValue = useDebounce(keyword, { wait: 500 });
  const userPermission = useSelector((state) => state.User.userPermission);

  const [pageNumber, setPageNumber, getPageNumber] = useGetState(1);
  const [pageSize, setPageSize, getPageSize] = useGetState(hasSearch ? 30 : 20);
  const [total, setTotal, getTotal] = useGetState(0);

  const [nextLoading, setNextLoading, getNextLoading] = useGetState(false);
  const scrollbarsRef = useRef(null);

  const tagColor = {
    股权变更: "#61D4AD",
    立案信息: "#AAD461",
    动产抵押: "#FEC054",
  };

  const extraClick = (item) => {
    expendChange(item.id);
  };

  const genExtra = (length, item) => {
    if (length) {
      return (
        <span
          className="extraText"
          onClick={() => {
            let obj = item;
            if (item.id === getActiveKey()) {
              obj = {};
            }
            extraClick(obj);
          }}
        >
          新增<span style={{ color: "#006AB2" }}>{length}</span>条
        </span>
      );
    }
    return null;
  };

  const genIcon = (panelProps) => {
    if (panelProps.isActive) {
      return <CaretUpOutlined style={{ cursor: "pointer" }} />;
    } else {
      return <CaretDownOutlined style={{ cursor: "pointer" }} />;
    }
  };

  const getActiveKeyInfoFunc = (key, page, page_size, isFirst) => {
    const [company_id, r_id, create_time, project_id] = key?.split("&&&&&");
    const params = {
      page,
      page_size,
      company_id,
      create_time,
      project_id,
      is_index: 1,
    };
    setActiveKeyInfo((prev) => {
      if (prev[key]) {
        prev[key].loading = true;
      } else {
        prev[key] = {
          loading: true,
          page: 1,
          pageSize: hasSearch ? 30 : 20,
          total: 0,
          data: [],
        };
      }
      return { ...prev };
    });
    if (getKeyword()) {
      params.keyword = getKeyword();
    }
    if (getStartDate()) {
      params.start_date = getStartDate();
    }
    if (getEndDate()) {
      params.end_date = getEndDate();
    }
    if (projectId) {
      params.company_id = r_id;
      params.is_index = 0;
    }
    return new Promise((reject) => {
      setTimeout(() => {
        let api = projectApi;
        if (isBond) {
          api = bondApi;
        }
        api.getSubDataInfo(params).then((res) => {
          if (res.code === 200) {
            setActiveKeyInfo((prev) => {
              prev[key].loading = false;
              prev[key].data = prev[key].data.concat(res.data.data);
              prev[key].total = res.data.total;
              prev[key].page = page;
              return { ...prev };
            });
          } else {
            message.error(res.message);
            setActiveKey(null);
          }
          reject(res);
        });
      }, 100);
    });
  };

  const expendChange = (key) => {
    setActiveKey(key);
    if (!key) {
      return;
    }
    if (getActiveKeyInfo()[key]) {
    } else {
      getActiveKeyInfoFunc(key, 1, getPageSize(), true);
    }
  };

  useThrottleEffect(
    () => {
      setForceUpdate(!forceUpdate);
    },
    [size],
    { wait: 1000 }
  );

  const getDataOnePage = () => {
    setPageNumber(1);
    setTimeout(() => {
      getData();
    }, 100);
  };

  const getData = () => {
    setLoading(true);
    let params = {
      page: getPageNumber(),
      page_size: getPageSize(),
    };
    if (getKeyword()) {
      params.keyword = getKeyword();
    }
    if (getStartDate()) {
      params.start_date = getStartDate();
    }
    if (getEndDate()) {
      params.end_date = getEndDate();
    }
    if (hasSearch) {
      params.query_all = 1;
    }
    if (projectId) {
      params.project_id = projectId;
    }
    let api = projectApi;
    if (isBond) {
      api = bondApi;
    }
    api.getSub(params).then((res) => {
      if (res.code === 200) {
        const data = res.data.data?.map((item) => {
          item.id =
            item?.company_id +
            "&&&&&" +
            item?.r_id +
            "&&&&&" +
            item.create_time +
            "&&&&&" +
            item.project_id;
          return item;
        });
        setSubscribeList(data);
        setTotal(res.data.total);
      } else {
        setSubscribeList([]);
        setTotal(0);
        message.error(res.message);
      }
      setActiveKey([]);
      setActiveKeyInfo({});
      setLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const onChange = async (dates, dateStrings) => {
    if (dates) {
      await setStartDate(dateStrings[0]);
      await setEndDate(dateStrings[1]);
    } else {
      await setStartDate(null);
      await setEndDate(null);
    }
    getDataOnePage();
  };

  const keywordChange = async (e) => {
    await setKeyword(e.target.value);
  };

  useEffect(() => {
    if (getKeyword() !== null) {
      getDataOnePage();
    }
  }, [debouncedValue]);

  const loadMore = () => {
    if (
      getNextLoading() ||
      getSubscribeList()?.length >= getTotal() ||
      loading
    ) {
      return;
    }
    let page = getPageNumber();
    page = page + 1;
    setPageNumber(page);
    setNextLoading(true);
    const params = {
      page: page,
      page_size: getPageSize(),
    };
    if (getKeyword()) {
      params.keyword = getKeyword();
    }
    if (getStartDate()) {
      params.start_date = getStartDate();
    }
    if (getEndDate()) {
      params.end_date = getEndDate();
    }
    if (hasSearch) {
      params.query_all = 1;
    }
    if (projectId) {
      params.project_id = projectId;
    }
    let api = projectApi;
    if (isBond) {
      api = bondApi;
    }
    api.getSub(params).then((res) => {
      console.log("获取更多!!!", page, res);
      if (res.code === 200) {
        setSubscribeList((prev) => {
          const data = res.data.data?.map((item) => {
            item.id =
              item?.company_id +
              "&&&&&" +
              item?.r_id +
              "&&&&&" +
              item.create_time +
              "&&&&&" +
              item.project_id;
            return item;
          });
          return [...prev, ...(data || [])];
        });
        setTotal(res.data?.total);
      } else {
        setSubscribeList([]);
        setTotal(0);
        message.error(res.message);
      }
      setNextLoading(false);
    });
  };

  //滚动事件 监听滚动
  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } =
      scrollbarsRef.current.getValues();
    if (scrollHeight - scrollTop <= clientHeight + 30) {
      loadMore();
    }
  };

  const goUrl = (project_id, code_name) => {
    let routers = infoRouter;
    if (isBond) {
      routers = bondRouter;
    }
    let route = routers.find((item) => {
      if (item.multiple) {
        return (
          item.menu_name_list?.includes(code_name) ||
          item.menu_name === code_name
        );
      }
      return item.menu_name === code_name;
    });
    let url = "";
    if (route && code_name) {
      let path = "/company/";
      if (isBond) {
        path = "/bond/";
      }
      url = window.location.origin + path + project_id + route.menu_url;
      if (route.multiple) {
        if (route.menu_name_list?.includes(code_name)) {
          url = url + `?type=${code_name}`;
        }
      }
      // if (route.menu_name === "疑似关联方分析") {
      // url = url + `?pathId=${data.data_id}`;
      // }
    } else {
      let path = "/company/";
      if (isBond) {
        path = "/bond/";
      }
      url = window.location.origin + path + project_id;
    }
    window.open(url, isBond ? "bondPage" : "projectPage");
  };

  return (
    <div className="subscribeContent">
      {hasHeader && (
        <div style={{ padding: "20px 20px 0 20px" }}>
          <HeaderTitle
            title="订阅"
            extra={
              <div className="iconBox">
                <Tooltip
                  placement="top"
                  title="全部订阅消息"
                  arrowPointAtCenter
                >
                  <HistoryOutlined
                    className="hoverIcon"
                    onClick={() => {
                      setVisible(true);
                    }}
                  />
                </Tooltip>
                {(isBond ? true : (projectId
                  ? projectInfo?.project_permission_urls.includes(
                      "CDD_PRJ_SUBSCRIBE"
                    )
                  : userPermission?.includes("CDD_HOMEPAGE_SUBSCRIBE_SET"))) && (
                  <Tooltip placement="top" title="订阅设置" arrowPointAtCenter>
                    <SettingOutlined
                      className="hoverIcon"
                      onClick={() => {
                        if (projectId) {
                          getLicenseValid().then((res) => {
                            if (res) {
                              let api = projectApi;
                              api.getMySub().then((res) => {
                                if (res.code === 200) {
                                  let item = res.data?.find(_=>Number(_.project_id) === Number(projectId))
                                  if(item){
                                    setSubscribeId(item.id)
                                  }else{
                                    setSubscribeId(null)
                                  }
                                } 
                                setTimeout(()=>{
                                  setSettingVisible(true);
                                },100)
                              });
                            }
                          });
                        } else {
                          setSettingListVisible(true);
                        }
                      }}
                    />
                  </Tooltip>
                )}
              </div>
            }
          ></HeaderTitle>
        </div>
      )}
      {hasSearch && (
        <div className="searchBox">
          {projectId ? (
            <></>
          ) : (
            <Input
              allowClear={true}
              placeholder={`输入${isBond ? '发行人' : '尽调对象'}名称搜索`}
              style={{ width: "calc(50% - 10px)", borderRadius: "30px" }}
              onChange={keywordChange}
              value={keyword}
            />
          )}
          <MyRangePicker
            style={{ width: "calc(50% - 10px)", borderRadius: "30px" }}
            format="YYYY-MM-DD"
            allowClear={true}
            onChange={onChange}
          ></MyRangePicker>
          {/* <RangePicker
            style={{ width: "calc(50% - 10px)", borderRadius: "30px" }}
            showTime
            format="YYYY-MM-DD"
            onChange={onChange}
          /> */}
        </div>
      )}
      {loading ? (
        <div
          style={{
            height: `calc(100% - ${hasSearch ? "60px" : "50px"})`,
            width: "100%",
          }}
        >
          <MySpin></MySpin>
        </div>
      ) : (
        <div
          style={{
            height: `calc(100% - ${hasSearch ? "60px" : "50px"})`,
            width: "100%",
            padding: "5px 0",
          }}
        >
          <Scrollbars
            autoHide={true}
            autoHideTimeout={5000}
            style={{
              height: `100%`,
              width: "100%",
              zIndex: 11,
            }}
            onScroll={handleScroll}
            ref={scrollbarsRef}
          >
            {subscribeList.length > 0 ? (
              <div className="subscriptionInformation">
                <Collapse
                  collapsible="header"
                  expandIconPosition="end"
                  expandIcon={genIcon}
                  activeKey={activeKey}
                  onChange={expendChange}
                  accordion
                >
                  {subscribeList.map((item, index) => (
                    <Panel
                      Panel
                      header={
                        <GenHeader
                          date={item.create_time}
                          companyName={item.company_name}
                          goUrl={goUrl}
                          info={item}
                          isBond={isBond}
                        ></GenHeader>
                      }
                      extra={genExtra(item.count, item)}
                      key={item.id}
                    >
                      <>
                        {((getActiveKeyInfo()[item.id] || {})?.data || [])?.map(
                          (_item) => (
                            <div className="panelItem" key={Math.random()}>
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  goUrl(
                                    isBond ? item.company_id : item.project_id,
                                    _item.func_code_name
                                  );
                                }}
                              >
                                <Tag
                                  style={{
                                    cursor: "pointer",
                                    color: _item?.event_color || "#006AB2",
                                    borderColor: _item?.event_color || "#006AB2",
                                  }}
                                >
                                  {_item.sub_content_code_name}
                                </Tag>
                              </div>
                              <div
                                className="subscriptionText"
                                onClick={() => {
                                  if (_item.event_code) {
                                    let route = bondRouter.find((__item) => {
                                      return (
                                        __item.menu_name ===
                                        _item?.func_code_name
                                      );
                                    });
                                    let url = "";
                                    if (route && _item?.func_code_name) {
                                      let path = "/bond/";
                                      url =
                                        window.location.origin +
                                        path +
                                        _item?.company_id +
                                        route.menu_url;
                                      url =
                                        url +
                                        "?event_code=" +
                                        _item?.event_code + '&r_id=' + _item?.r_id + '&r_name=' + _item?.r_name;
                                    }
                                    window.open(url, _item?.company_id);
                                  }
                                }}
                                style={{
                                  cursor: _item.event_code
                                    ? "pointer"
                                    : "unset",
                                }}
                              >
                                <Paragraph
                                  ellipsis={{
                                    tooltip: {
                                      title: (
                                        <div className="tableColumnsParagraphTip">
                                          {_item.content}
                                        </div>
                                      ),
                                      overlayClassName:
                                        "tableColumnsParagraphTooltip",
                                    },
                                    rows: 3,
                                    expandable: false,
                                  }}
                                >
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: _item.content,
                                    }}
                                  ></span>
                                  {/* {_item.content} */}
                                </Paragraph>
                              </div>
                            </div>
                          )
                        )}

                        {(getActiveKeyInfo()[item.id] || {})?.loading ? (
                          <div className="loadingBox">
                            <LoadingOutlined />
                            获取中...
                          </div>
                        ) : (
                          ((getActiveKeyInfo()[item.id] || {})?.data || [])
                            ?.length <
                            (getActiveKeyInfo()[item.id] || {})?.total && (
                            <div
                              className="loadMoreBox"
                              onClick={() => {
                                const top =
                                  scrollbarsRef?.current?.getScrollTop();
                                getActiveKeyInfoFunc(
                                  item.id,
                                  (getActiveKeyInfo()[item.id] || {})?.page + 1,
                                  (getActiveKeyInfo()[item.id] || {})?.pageSize
                                ).then((res) => {
                                  setTimeout(() => {
                                    scrollbarsRef?.current?.scrollTop(top);
                                  }, 100);
                                });
                              }}
                            >
                              加载更多
                            </div>
                          )
                        )}
                      </>
                    </Panel>
                  ))}
                  {nextLoading && (
                    <Divider className="loadingMoreDivider" dashed>
                      加载中...
                    </Divider>
                  )}
                </Collapse>
              </div>
            ) : (
              <div className="noData">
                <Empty></Empty>
              </div>
            )}
          </Scrollbars>
        </div>
      )}

      {/* 嵌套的抽屉 */}
      <SubscribeDrawer
        projectId={projectId}
        visible={visible}
        onClose={() => setVisible(false)}
        isBond={isBond}
      ></SubscribeDrawer>

      <SubscribeListDrawer
        visible={settingListVisible}
        onClose={() => setSettingListVisible(false)}
        isBond={isBond}
      ></SubscribeListDrawer>

      <SubscribeSettingDrawer
        subId={subscribeId}
        projectId={projectId}
        visible={settingVisible}
        success={getDataOnePage}
        onClose={() => setSettingVisible(false)}
        isBond={isBond}
      ></SubscribeSettingDrawer>
    </div>
  );
};

export default Subscribe;
