import HeaderTitle from "../../components/HeaderTitle";
import Subscribe from "../Subscribe";
import "./index.scss";
import { useState, useEffect, useRef } from "react";
import { Input, Button, Divider } from "antd";
import {
  SearchOutlined,
  PlusCircleOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import bondApi from "../../apis/bond/index";
import { useGetState } from "ahooks";
import MySpin from "../../components/MySpin";
import { debounce, getLicenseValid } from "../../unit/unit";
import Scrollbars from "react-custom-scrollbars";
import MyEmpty from "../../components/Empty";
import BondAddPage from "./components/BondAddPage";
import InfiniteScroll from "react-infinite-scroll-component";
import BondCard from "./components/BondCard";

const Bond = (props) => {
  const [issuerKey, setIssuerKey, getIssuerKey] = useGetState(null);
  const [isAdd, setIsAdd] = useState(false);

  const [pageNumber, setPageNumber, getPageNumber] = useGetState(1);
  const [pageSize, setPageSize] = useState(20);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData, getData] = useGetState([]);

  const [orderField, setOrderField, getOrderField] =
    useGetState("latest_update"); //排序字段
  const [orderAsc, setOrderAsc, getOrderAsc] = useGetState(-1); //排序顺序：1升序/-1降序
  const [isOnlySelf, setIsOnlySelf, getIsOnlySelf] = useGetState(false); //排序顺序：1升序/-1降序


  const getIssuersListFunc = (noLoading, addOld) => {
    if (!noLoading) {
      setLoading(true);
    }
    const params = {
      page_size: pageSize,
      page: getPageNumber(),
      order_field: getOrderField(),
      order_type: getOrderAsc() === 1 ? "asc" : "desc",
      is_only_self: getIsOnlySelf() ? 1 : undefined,
      issuer_key: getIssuerKey(),
    };
    bondApi.getIssuersList(params).then((res) => {
      console.log("--------", res);
      if (res.code === 200) {
        if (res.data.user_rel_total === 0) {
          setTotal(0);
          setData([]);
          setIsAdd(true);
          setLoading(false);
          return;
        }
        let old = [];
        if (addOld) {
          old = data;
        }
        setTotal(res.data.total);
        setData(old?.concat(res.data.data || []));
      } else {
        setTotal(0);
        setData([]);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    if (!isAdd) {
      setPageNumber(1);
      setTimeout(() => {
        getIssuersListFunc();
      }, 100);
    }
  }, [isAdd]);

  /* 排列顺序改变 */
  const orderChange = async (e, key) => {
    if (orderField === key) {
      const asc = await getOrderAsc();
      await setOrderAsc(asc === 1 ? -1 : 1);
    } else {
      await setOrderField(key);
      await setOrderAsc(1);
    }
    setPageNumber(1);
    setTimeout(() => {
      getIssuersListFunc();
    }, 100);
  };

  const loadMoreData = async () => {
    if (loading) {
      return;
    }
    await setPageNumber(getPageNumber() + 1);
    setTimeout(() => {
      getIssuersListFunc(true, true);
    }, 100);
  };

  const deleteCallBack = () => {
    setPageNumber(1);
    setTimeout(() => {
      getIssuersListFunc();
    }, 100);
  };

  const addSuccessCallBack = () => {
    setLoading(true);
    setIsAdd(false);
    // setPageNumber(1);
    // setTimeout(() => {
    //   getIssuersListFunc();
    // }, 100);
  };

  //添加界面
  if (isAdd) {
    return (
      <BondAddPage
        addSuccessCallBack={addSuccessCallBack}
      ></BondAddPage>
    );
  }

  //展示界面
  return (
    <div className="bondContent">
      <div className="leftBondContent">
        <HeaderTitle
          title="发行人及动态"
          extra={
            <div className="headerSearchBox">
              <Button
                size="middle"
                type="primary"
                icon={<PlusCircleOutlined />}
                onClick={() => {
                  getLicenseValid(false, true).then(res=>{
                    if(res){
                      setIsAdd(true);
                    }
                  })
                }}
              >
                添加
              </Button>
            </div>
          }
        ></HeaderTitle>
        <div className="leftBondContentBox">
          {loading ? (
            <MySpin></MySpin>
          ) : (
            <div className="bondBox">
              <div className="totalBox">
                <div className="totalDiv">
                  共<span>{total}</span>家
                </div>
                <Input
                  prefix={<SearchOutlined style={{ color: "#D8D8D8" }} />}
                  value={issuerKey}
                  onChange={(e) => {
                    setIssuerKey(e.target.value?.trim());
                  }}
                  style={{
                    height: "26px",
                    border: "none",
                    borderBottom: "1px solid #D8D8D8",
                    width: "260px",
                    padding: "4px",
                    borderRadius: "0",
                    boxShadow: "none",
                  }}
                  onPressEnter={() => {
                    setPageNumber(1);
                    setTimeout(() => {
                      getIssuersListFunc();
                    }, 100);
                  }}
                  onBlur={() => {
                    setPageNumber(1);
                    setTimeout(() => {
                      getIssuersListFunc();
                    }, 100);
                  }}
                  placeholder="输入发行人名称或债券简称/代码搜索"
                  size="middle"
                />
              </div>
              <div className="searchBox">
                <div className="optionsBox">
                  {[
                    { label: "最新动态", key: "latest_update", width: "70px" },
                    { label: "最近查看", key: "recent_view", width: "70px" },
                    { label: "发行人名称", key: "company_name", width: "85px" },
                  ].map((item) => (
                    <span
                      key={item.key}
                      className="optionItem"
                      onClick={debounce(orderChange, 200, item.key)}
                      style={{
                        color: orderField === item.key ? "#0068b2" : "#535353",
                        width: item.width,
                      }}
                    >
                      {item.label}
                      {orderField === item.key &&
                        (orderAsc === 1 ? (
                          <CaretUpOutlined />
                        ) : (
                          <CaretDownOutlined />
                        ))}
                    </span>
                  ))}
                </div>
                <div className="optionsBox">
                  {[
                    { label: "发行人及关联方", value: false, width: "98px" },
                    { label: "仅发行人", value: true, width: "57px" },
                  ].map((item) => (
                    <span
                      className="optionItem"
                      onClick={() => {
                        setIsOnlySelf(item.value);
                        setPageNumber(1);
                        setTimeout(() => {
                          getIssuersListFunc();
                        }, 100);
                      }}
                      style={{
                        color:
                          isOnlySelf === item.value ? "#0068b2" : "#535353",
                        borderBottom:
                          isOnlySelf === item.value
                            ? "1px #0068b2 solid"
                            : "none",
                        width: item.width,
                      }}
                    >
                      {item.label}
                    </span>
                  ))}
                </div>
              </div>
              <div className="bondInfiniteScrollBox" id="bondInfiniteScrollBox">
                {data?.length > 0 ? (
                  <InfiniteScroll
                    dataLength={data.length}
                    next={loadMoreData}
                    loader={<Divider className="loadingBox"><span style={{display:'flex'}}>加载中&nbsp;<MySpin></MySpin></span></Divider>}
                    hasMore={data.length < total}
                    scrollableTarget="bondInfiniteScrollBox"
                    // endMessage={<Divider className="noDataDivider"></Divider>}
                  >
                    <div className="cardBox">
                      {data.map((item, index) => (
                        <BondCard
                          info={item}
                          key={item.company_id}
                          deleteCallBack={deleteCallBack}
                        ></BondCard>
                      ))}
                    </div>
                  </InfiniteScroll>
                ) : (
                  <MyEmpty></MyEmpty>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="rightBondContent">
        <Subscribe hasHeader={true} isBond={true}></Subscribe>
      </div>
    </div>
  );
};

export default Bond;
