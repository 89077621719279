import "./index.scss";
import { useParams } from "react-router-dom";
import MyTable from "../../../components/MyTable";
import { useEffect, useState } from "react";
import CommentsHeaderTitle from "../../../components/CommentsHeaderTitle";
import Scrollbars from "react-custom-scrollbars";
import MySpin from "../../../components/MySpin";
import {
  Select,
  Tag,
  Checkbox,
  message,
  Pagination,
  Image,
  Button,
  Typography,
  Modal,
  Avatar,
  Tooltip,
} from "antd";
import { useGetState } from "ahooks";
import { ArrayIsEqual } from "../../../unit/unit";
import projectApi from "../../../apis/project";
import { useSelector } from "react-redux";
import MyEmpty from "../../../components/Empty";
import moment from "moment";
import { SwapLeftOutlined } from "@ant-design/icons";
import { showInfoFunc } from "../../../components/MyTable";
import InfoIcon from "../../../components/Icons/InfoIcon";
import CommentsIcon from "../../../components/Icons/CommentsIcon";
import img1 from "../../../assets/警告@3x.png";

const { Option } = Select;
const { Paragraph } = Typography;

const PublicSentimentCard = (props) => {
  const { info, lookUp, getData, dataTable, typeName } = props;
  const { id } = useParams();
  const imgUrl = info?.images?.val?.at(0);
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};
  return (
    <div className="publicSentimentCard">
      {imgUrl ? (
        <Image width={260} height={160} src={imgUrl} preview={false} />
      ) : (
        <Avatar
          className="hoverPic publicSentimentImg"
          size={260}
          shape="square"
          onClick={() => {
            // imgClick && imgClick(cardData);
          }}
        >
          {info?.companyName?.val}
        </Avatar>
      )}
      <div className="publicSentimentCardContent">
        <a
          className="cardTitle"
          onClick={(e) => {
            e.stopPropagation();
            lookUp(info?.contentHtml?.val, info);
          }}
        >
          {info?.title?.val}
        </a>
        <div style={{ display: "flex", width: "100%" }}>
          <div className="contentBox_">
            <div className="labelBox">
              <span>来源</span> {info?.webSite?.val}
              <span style={{ marginLeft: "26px" }}>当事人</span>
              {(info.companyList?.val || []).map((companyInfo, _index) => {
                return (
                  <span
                    key={companyInfo.companyId}
                    style={{ marginRight: "0px" }}
                  >
                    {_index === 0 ? "" : "、"}
                    <a
                      onClick={(e) => {
                        e.stopPropagation();
                        showInfoFunc(
                          {
                            click_legal_request: true,
                          },
                          {
                            companyId: { val: companyInfo.companyId },
                            companyName: { val: companyInfo.companyName },
                          },
                          "新闻舆情",
                          id,
                          "companyName"
                        );
                      }}
                    >
                      {companyInfo?.companyName}
                    </a>
                    {companyInfo?.risk_tips && (
                      <Tooltip
                        placement="top"
                        title="工商信息风险提示 点击查看"
                        arrowPointAtCenter
                      >
                        <img
                          src={img1}
                          alt=""
                          className="warnImg"
                          onClick={(e) => {
                            e.stopPropagation();
                            showInfoFunc(
                              {
                                click_legal_request: true,
                              },
                              {
                                companyId: { val: companyInfo.companyId },
                                companyName: { val: companyInfo.companyName },
                              },
                              "新闻舆情",
                              id,
                              "companyName"
                            );
                          }}
                        />
                      </Tooltip>
                    )}
                  </span>
                );
              })}
            </div>
            {/* <div className="cardText">{info?.content?.val}</div> */}
            <Paragraph
              className="cardText"
              ellipsis={{
                rows: 5,
                expandable: true,
                symbol: (
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      lookUp(info?.contentHtml?.val, info);
                    }}
                  >
                    更多
                  </span>
                ),
              }}
            >
              {info?.content?.val}
            </Paragraph>
          </div>

          <div className="btnBox">
            <div className="timeBox">
              {info?.publishTime?.val &&
                moment(info?.publishTime?.val).format("MM-DD")}
            </div>
            <div className="yearBox" style={{ marginBottom: "15px" }}>
              {info?.publishTime?.val &&
                moment(info?.publishTime?.val).format("YYYY")}
            </div>
            <Button
              type="primary"
              onClick={() => {
                window.open(info?.url?.val, "_blank");
              }}
            >
              原链接 <SwapLeftOutlined />
            </Button>
          </div>
        </div>
      </div>

      <div className="iconBox">
        {projectInfo?.project_permission_urls.includes(
          "CDD_PRJ_COMMENT_VIEW"
        ) && (
          <InfoIcon
            getData={() => {
              getData(true);
            }}
            info={{
              id: info.id?.val,
              comments: info.comments,
            }}
            mode={20}
            dataInfo={info}
            dataTable={dataTable}
            typeName={typeName}
            projectId={projectInfo.id}
          />
        )}

        {projectInfo?.project_permission_urls.includes("CDD_PRJ_COMMENT") ? (
          <CommentsIcon
            getData={() => {
              getData(true);
            }}
            info={{
              id: info.id?.val,
            }}
            mode={20}
            dataInfo={info}
            dataTable={dataTable}
            typeName={typeName}
            projectId={projectInfo.id}
          />
        ) : null}
      </div>
    </div>
  );
};
const PublicSentiment = (props) => {
  const dataTable = "company_public_opinion";
  const typeName = "新闻舆情";
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [updateTime, setUpdateTime] = useState(null);
  const [publicOpinionList, setPublicOpinionList] = useState([]);

  const [checkAll, setCheckAll] = useState(false);
  const [checkAllIndeterminate, setCheckAllIndeterminate] = useState(true);
  const [subjectScopeList, setSubjectScopeList, getSubjectScopeList] =
    useGetState([
      "CDD_SUB_DD_CORP",
      "CDD_SUB_CTRL_SUB_CORP",
      "CDD_SUB_CTRL_SHRHLDR",
    ]);
  const [subjectScopeOptions, setSubjectScopeOptions] = useState([]);
  const [oldSubjectScopeList, setOldSubjectScopeList, getOldSubjectScopeList] =
    useGetState([]);
  const [relatedPartiesCountObj, setRelatedPartiesCountObj] = useState({});

  const [pageNumber, setPageNumber, getPageNumber] = useGetState(1);
  const [pageSize, setPageSize, getPageSize] = useGetState(10);
  const [total, setTotal] = useState(0);

  const [visible, setVisible] = useState(false);
  const [showContent, setShowContent] = useState("");
  const [showImgList, setShowImgList] = useState([]);

  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};

  //转成第一页 获取数据
  const getOnePageData = async (flag) => {
    await setPageNumber(1);
    await setTimeout(() => {
      getData(flag);
    }, 100);
  };
  //多选选择框tag选项样式
  const tagRender = (props) => {
    const { label, value, closable } = props;
    const onClose = async () => {
      await setSubjectScopeList((prev) => {
        prev = prev.filter((_) => _ !== value);
        if (prev.length === 0) {
          setCheckAll(false);
          setCheckAllIndeterminate(false);
        }
        return [...prev];
      });
      await getData(true);
    };
    return (
      <Tag
        // onClose={onClose}
        // closable={closable}
        style={{
          marginRight: 3,
        }}
      >
        {label?.at(0)}
      </Tag>
    );
  };
  //获取关联方对应的数量
  const getCodeNumber = () => {
    projectApi
      .getOptionsNumber(id, {
        name: dataTable,
        companyId: projectInfo.company_id,
      })
      .then((res) => {
        if (res.code === 200) {
          setRelatedPartiesCountObj(res.data);
        } else {
          setRelatedPartiesCountObj({});
        }
      });
  };
  //获取code枚举属性
  const getCodeInfo = () => {
    projectApi.getDicCodeInfo("CDD_SUB").then((res) => {
      if (res.code === 200) {
        setSubjectScopeOptions(res.data);
        getCodeNumber();
      } else {
        setSubjectScopeOptions([]);
      }
    });
  };
  useEffect(() => {
    getCodeInfo();
    getData();
  }, []);
  const getData = () => {
    setLoading(true);
    const params = {
      page_size: getPageSize(),
      page_number: getPageNumber(),
    };
    if (getSubjectScopeList().length > 0) {
      params.sub_type = JSON.stringify(getSubjectScopeList());
    }

    projectApi.getDataInfo(dataTable, id, params).then((res) => {
      console.log(
        `查看 ${typeName} 数据,表名为 ${dataTable},第 ${
          getPageSize() * (getPageNumber() - 1)
        } -  ${getPageSize() * getPageNumber()} 条`,
        "参数为:",
        params,
        "结果为:",
        res
      );
      if (res.code === 200) {
        setTotal(res.data.total);
        setUpdateTime(res.data.data_update_time);
        setPublicOpinionList(res.data.data);
      } else {
        message.error(res.message);
        setTotal(0);
        setUpdateTime(null);
        setPublicOpinionList([]);
      }
      setLoading(false);
    });
  };
  //页码变化
  const onChange = async (page, pageSize) => {
    await setPageSize(pageSize);
    await setPageNumber(page);
    await getData(true);
  };
  //
  const lookUp = (val, info) => {
    setShowContent(val);
    setShowImgList(info?.images?.val || []);
    setVisible(true);
  };

  return (
    <div className="PublicSentimentBox">
      <div className="infoBox">
        <CommentsHeaderTitle
          title={typeName}
          dataTable={dataTable}
          typeName={typeName}
        ></CommentsHeaderTitle>
        <Scrollbars
          autoHide={true}
          autoHideTimeout={5000}
          style={{
            height: "calc(100% - 20px)",
            width: "100%",
          }}
        >
          {loading ? (
            <MySpin></MySpin>
          ) : (
            <div className="tableBox">
              <div className="dateBox">
                <div className="dataInput">
                  更新时间：{updateTime || "-"}
                  <div className="inputBox">
                    <div className="label">主体范围</div>
                    <Select
                      mode="multiple"
                      value={subjectScopeList}
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="全部"
                      showArrow={true}
                      bordered={false}
                      maxTagCount="responsive"
                      className="subjectScopeSelect"
                      tagRender={tagRender}
                      filterOption={(inputValue, option) =>
                        option.children.includes(inputValue)
                      }
                      onChange={async (value) => {
                        if (value.length === subjectScopeOptions.length) {
                          await setCheckAll(true);
                          await setCheckAllIndeterminate(false);
                        } else {
                          await setCheckAllIndeterminate(true);
                          await setCheckAll(false);
                        }
                        if (value.length === 0) {
                          await setCheckAllIndeterminate(false);
                        }
                        await setSubjectScopeList(value);
                        // getData(true);
                      }}
                      onFocus={() => {
                        setOldSubjectScopeList(getSubjectScopeList());
                      }}
                      onBlur={() => {
                        if (
                          !ArrayIsEqual(
                            getOldSubjectScopeList(),
                            getSubjectScopeList()
                          )
                        ) {
                          getOnePageData(true);
                        }
                      }}
                      onClear={async () => {
                        setCheckAll(false);
                        setCheckAllIndeterminate(false);
                        await setSubjectScopeList([]);
                        await getOnePageData(true);
                      }}
                      dropdownRender={(menu) => (
                        <div className="subjectScopeSearchPoP">
                          <Checkbox
                            style={{ margin: "5px", marginLeft: "12px" }}
                            indeterminate={checkAllIndeterminate}
                            checked={checkAll}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSubjectScopeList(
                                  subjectScopeOptions.map(
                                    (_) => _.dic_item_code
                                  )
                                );
                              } else {
                                setSubjectScopeList([]);
                              }
                              setCheckAll(e.target.checked);
                              setCheckAllIndeterminate(false);
                              e.stopPropagation();
                            }}
                          >
                            全选
                          </Checkbox>
                          {menu}
                        </div>
                      )}
                    >
                      {subjectScopeOptions.map((item, index) => (
                        <Option
                          key={item.dic_item_code}
                          value={item.dic_item_code}
                        >
                          <a className="textItem" title={item.dic_item_name}>
                            {index + ". " + item.dic_item_name}
                          </a>{" "}
                          <Tag
                            style={
                              !relatedPartiesCountObj[item.dic_item_code]
                                ? {
                                    color: "#535353",
                                    background: "#EFEFEF",
                                    borderColor: "#EFEFEF",
                                  }
                                : {
                                    color: "#fff",
                                    background: "#0068B2",
                                    borderColor: "#0068B2",
                                  }
                            }
                          >
                            {relatedPartiesCountObj[item.dic_item_code] || 0}
                          </Tag>
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
              <div className="tableContent">
                {total === 0 ? (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <MyEmpty></MyEmpty>
                  </div>
                ) : (
                  <Scrollbars
                    autoHide={true}
                    autoHideTimeout={5000}
                    style={{
                      height: total > 10 ? "calc(100% - 50px)" : "100%",
                      width: "100%",
                    }}
                  >
                    <div className="cardListBox">
                      {publicOpinionList.map((item) => (
                        <PublicSentimentCard
                          dataTable={dataTable}
                          typeName={typeName}
                          getData={getData}
                          lookUp={lookUp}
                          key={item?.id?.val}
                          info={item}
                        ></PublicSentimentCard>
                      ))}
                    </div>
                  </Scrollbars>
                )}
                {total > 10 ? (
                  <div className="paginationBox">
                    <Pagination
                      className="myPagination"
                      showQuickJumper
                      current={pageNumber}
                      total={total}
                      pageSize={pageSize}
                      pageSizeOptions={[10, 20, 50, 100]}
                      onChange={onChange}
                      showTotal={(total) => `共 ${total} 条`}
                      showSizeChanger={true}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </Scrollbars>
      </div>
      <Modal
        title="详情"
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        //   onOk={handleOk}
        wrapClassName="publicSentimentBoxModal"
        centered
        footer={null}
      >
        <div className="showContentBox" key={Math.random()}>
          <span
            dangerouslySetInnerHTML={{
              __html: showContent,
            }}
          ></span>
          <div className="imgBox">
            {showImgList.map((img) => (
              <Image height={160} src={img} rootClassName="showContentImg" />
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default PublicSentiment;
