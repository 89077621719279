import { useEffect, useRef, useState, useMemo } from "react";
import G6 from "@antv/g6";
import "./index.scss";
import { getLicenseValid, guid } from "../../../../../unit/unit";
import {
  VerticalAlignBottomOutlined,
  RightOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import projectApi from "../../../../../apis/project";
import { useParams } from "react-router-dom";
import { showInfoFunc } from "../../../../../components/MyTable";
import { Checkbox, Select, Button } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
import { useGetState } from "ahooks";
import MySpin from "../../../../../components/MySpin";

const Chart = (props) => {
  const { chartData, checkDate } = props;
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const contentId = useRef(guid());
  const graph = useRef(null);
  const downloadGraph = useRef(null);

  const [path, setPath] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [addInformation, setAddInformation] = useState(false);
  const [pathsGroup, setPathsGroup] = useState([]);

  const [companyOptions, setCompanyOptions] = useState([]);
  const [searchCompany, setSearchCompany, getSearchCompany] = useGetState(null);
  const [bgShow, setBgShow] = useState(false);

  const { id } = useParams();
  const projectInfo = useSelector((state) => state.Project.projectInfo) || {};

  const timer = useRef();
  const timer2 = useRef();
  const sTypeColorMap = {
    尽调对象: "#0068B2",
    关联方: "#AAD461",
    供应商: "#C88EED",
    经销商: "#6CAEFF",
    客户: "#FEC054",
    非经营性资金往来: "#C6C0FC",
    其他: "#61D4AD",
  };

  const twoWayTypes = [
    "邮箱",
    "地址",
    "电话",
    "网址",
    "法院公告",
    "法律诉讼",
    "专利",
    "商标",
    "作品著作",
    "软件著作",
  ];

  //判断字符串是否是中文
  const isChinese = (temp) => {
    var re = /[^\u4E00-\u9FA5]/;
    if (re.test(temp)) return false;
    return true;
  };

  //判断字符串是否是英文
  const isEnglish = (temp) => {
    var re = /^[a-zA-Z]+$/;
    if (re.test(temp)) return true;
    return false;
  };

  //计算字符串长度
  const calculationWidth = (str) => {
    let width = 0;
    for (let i = 0; i < str.length; i++) {
      width = width + (isEnglish(str[i]) ? 9 : str?.length <= 8 ? 17 : 15.5);
    }
    return Math.ceil(width);
  };

  const initPath = async (path, addInformation) => {
    let _nodes = [];
    let _edges = [];
    path.forEach((item) => {
      item?.path?.at(0)?.forEach((_item) => {
        const [node1, type, node2] = _item;
        let node1Id = guid();
        let node2Id = guid();
        const _node1 = _nodes.find(
          (_) => _.entity_id === node1?.property?.entity_id
        );
        const _node2 = _nodes.find(
          (_) => _.entity_id === node2?.property?.entity_id
        );

        const _edgeIndex = _edges.findIndex(
          (_) =>
            _.sourceId === node1?.property?.entity_id &&
            _.targetId === node2?.property?.entity_id
        );

        if (!_node1) {
          _nodes.push({
            label: node1?.property?.name,
            _label: node1.label,
            entity_id: node1?.property?.entity_id,
            name: node1?.property?.name,
            id: node1Id,
            s_type: node1?.s_type,
          });
        } else {
          node1Id = _node1.id;
        }

        if (!_node2) {
          _nodes.push({
            label: node2?.property?.name,
            _label: node2.label,
            entity_id: node2?.property?.entity_id,
            name: node2?.property?.name,
            id: node2Id,
            s_type: node2?.s_type,
          });
        } else {
          node2Id = _node2.id;
        }

        if (_edgeIndex === -1) {
          _edges.push({
            source: node1Id,
            sourceId: node1?.property?.entity_id,
            targetId: node2?.property?.entity_id,
            target: node2Id,
            // label:type.type,
            _label: [type.type],
            _type_desc: [type.type_desc],
            property: type.property,
          });
          let flag = false;
          twoWayTypes.forEach((item) => {
            if (type.type?.includes(item)) {
              flag = true;
            }
          });
          //双箭头
          if (flag) {
            _edges.push({
              target: node1Id,
              targetId: node1?.property?.entity_id,
              sourceId: node2?.property?.entity_id,
              source: node2Id,
              _label: [],
              _type_desc: [],
            });
          }
        } else {
          if (!_edges[_edgeIndex]?._label?.includes(type.type)) {
            _edges[_edgeIndex]._label.push(type.type);
            _edges[_edgeIndex]._type_desc.push(type.type_desc);
          }
        }
      });
    });
    await setNodes(_nodes);
    await setEdges(_edges);
    await initChart(
      {
        nodes: _nodes,
        edges: _edges,
      },
      false,
      addInformation
    );
  };

  //递归边 改变样式
  const filterHoverNodesFunc = (node, isFirst) => {
    const nodes = [node];
    const endNodes = [];
    const model = node.getModel();
    const findNodeFunc = (_node) => {
      const edges = _node.getEdges();
      const model = _node?.getModel();

      edges.forEach((edge) => {
        edge.toFront();
        const sourceNode = edge.getSource();

        if (sourceNode) {
          const sourceModel = sourceNode?.getModel();
          //不是同一个节点
          if (model?.id !== sourceModel?.id) {
            //不是关联方 节点
            if (!sourceModel?.s_type) {
              //数组中 不存在
              if (!nodes?.some((_) => _?.getModel()?.id === sourceModel?.id)) {
                nodes.push(sourceNode);
                findNodeFunc(sourceNode);
              }
            } else {
              //数组中 不存在
              if (
                !endNodes?.some((_) => _?.getModel()?.id === sourceModel?.id)
              ) {
                endNodes.push(sourceNode);
              }
            }
          }
        }

        const targetNode = edge.getTarget();
        if (targetNode) {
          const targetModel = targetNode?.getModel();
          //不是同一个节点
          if (model?.id !== targetModel?.id) {
            //不是关联方 节点
            if (!targetModel?.s_type) {
              //数组中 不存在
              if (!nodes?.some((_) => _?.getModel()?.id === targetModel?.id)) {
                nodes.push(targetNode);
                findNodeFunc(targetNode);
              }
            } else {
              //数组中 不存在
              if (
                !endNodes?.some((_) => _?.getModel()?.id === targetModel?.id)
              ) {
                endNodes.push(targetNode);
              }
            }
          }
        }
      });
    };
    findNodeFunc(node);
    const edges = [];
    nodes?.forEach((node) => {
      const _edges = node.getEdges();
      _edges.forEach((edge) => {
        edges.push(edge);
      });
    });

    const allEdges = graph.current.getEdges();
    allEdges.forEach((edge) => {
      setTimeout(() => {
        if (edges?.some((_) => _?._cfg?.id === edge?._cfg?.id)) {
          graph.current.setItemState(edge, "hover", true);
          edge.toFront();
        } else {
          graph.current.setItemState(edge, "hover", false);
          edge.toBack();
        }
      }, 100);
    });
    const allNodes = graph.current.getNodes();
    allNodes.forEach((node) => {
      if (
        nodes?.some((_) => _?._cfg?.id === node?._cfg?.id) ||
        endNodes?.some((_) => _?._cfg?.id === node?._cfg?.id)
      ) {
        graph.current.setItemState(node, "hover", true);
        node.toFront();
      } else {
        graph.current.setItemState(node, "hover", false);
        node.toBack();
      }
    });
  };

  //选中节点和边 添加 取消样式的方法
  const nodeActiveFunc = (node, flag) => {
    if (flag) {
      filterHoverNodesFunc(node, true);
    } else {
      const allEdges = graph.current.getEdges();
      allEdges.forEach((edge) => {
        graph.current.clearItemStates(edge, "hover");
        const states = edge.getStates();
        graph.current.setItemState(edge, "noHover", !states);
        edge.toBack();
      });
      const allNodes = graph.current.getNodes();
      allNodes.forEach((node) => {
        graph.current.clearItemStates(node, "hover");
        const states = node.getStates();
        graph.current.setItemState(node, "noHover", !states);
        node.toBack();
      });
    }
  };

  const initChart = (data, isDownLoad, addInformation) => {
    if (graph.current && !isDownLoad) {
      graph.current.destroy();
      graph.current = null;
    }
    const container = document.getElementById(
      isDownLoad ? "downloadCanvasBox" : contentId.current
    );

    const width = container.scrollWidth;
    const height = container.scrollHeight;
    const defaultEdgeStyle = {
      stroke: "#D8D8D8",
      endArrow: {
        fill: "#D8D8D8",
        path: G6.Arrow.triangle(10, 6, 0),
        d: 0,
      },
    };
    const defaultNodeStyle = {
      // fill: "#91d5ff",
      // stroke: "#40a9ff",
      radius: 5,
    };
    const defaultLabelCfg = {
      style: {
        fill: "#000",
        fontSize: 12,
      },
    };
    G6.registerEdge("chart-line", {
      draw(cfg, group) {
        const startPoint = cfg.startPoint;
        const endPoint = cfg.endPoint;
        const { style } = cfg;
        let shape = null;
        group.addShape("path", {
          attrs: {
            stroke: style.stroke,
            endArrow: style.endArrow,
            label: "triangleRect arrow",
            path: [
              ["M", startPoint.x, startPoint.y],
              ["L", endPoint.x, endPoint.y],
            ],
          },
          name: "flowLinePath",
        });
        const text = addInformation
          ? cfg._type_desc.join("、") && `${cfg._type_desc.join("、")}`
          : cfg._label.join("、");
        const len = text.split("").reduce((a, b) => {
          const reg = /[\u4e00-\u9fa5]/g;
          if (b.match(reg)) {
            a += 12;
          } else {
            a += 6;
          }
          return a;
        }, 0);
        shape = group.addShape("text", {
          attrs: {
            fill: "#0068B2",
            fontSize: 12,
            text: text,
            x: (endPoint.x - startPoint.x) / 2 + startPoint.x - len / 2,
            y: (endPoint.y - startPoint.y) / 2 + startPoint.y,
          },
          name: "percentText",
        });

        return shape;
      },
      setState(name, value, item) {
        const group = item.get("group");
        if (name === "noHover") {
          const path = group.find((e) => e.get("name") === "flowLinePath");
          const textPath = group.find((e) => e.get("name") === "percentText");
          path?.attr({
            stroke: "#D8D8D8",
            lineWidth: 1,
            opacity: 1,
            endArrow: {
              fill: "#D8D8D8",
              path: G6.Arrow.triangle(10, 6, 0),
              d: 0,
            },
          });
          textPath?.attr({
            opacity: 1,
          });
        }
        if (name === "hover") {
          const path = group.find((e) => e.get("name") === "flowLinePath");
          const textPath = group.find((e) => e.get("name") === "percentText");
          path?.attr({
            stroke: value ? "#006AB2" : "#D8D8D8",
            opacity: value ? 1 : 0.2,
            endArrow: {
              fill: value ? "#006AB2" : "#D8D8D8",
              path: G6.Arrow.triangle(10, 6, 0),
              d: 0,
            },
          });
          textPath?.attr({
            opacity: value ? 1 : 0.2,
          });
        }
      },
    });
    G6.registerNode(
      "path-node",
      {
        options: {
          size: [150, 20],
          stroke: "#91d5ff",
          fill: "#91d5ff",
        },
        draw(cfg, group) {
          const styles = this.getShapeStyle(cfg);
          const { labelCfg = {} } = cfg;
          if (!cfg.name) {
            cfg.name = "（工商公示异常企业）";
          }
          styles.width =
            (cfg._label !== "Company"
              ? 40
              : cfg.name
              ? calculationWidth(cfg.name)
              : 0) + 20;
          const w = styles.width;
          const h = 30; //styles.height;
          const keyShape = group.addShape("rect", {
            draggable: true,
            name: "key-rect",
            attrs: {
              ...styles,
              height: 50,
              x: -w / 2,
              y: -h / 2,
            },
          });
          const rectGroup = group.addGroup({
            name: "rect-box",
            draggable: true,
          });
          let color = "#D8D8D8",
            textColor = "#313131",
            fillColor = "#fff";
          const s_type_item = cfg.s_type?.find((_) =>
            Object.keys(sTypeColorMap).includes(_.full_name)
          );
          if (s_type_item) {
            color = sTypeColorMap[s_type_item?.full_name];
            fillColor = sTypeColorMap[s_type_item?.full_name];
            textColor = "#fff";
          }
          if (cfg._label === "Company") {
            rectGroup.addShape("rect", {
              draggable: true,
              attrs: {
                width: w - 20,
                height: h,
                stroke: color,
                fill: fillColor,
                cursor: "pointer",
                radius: 5,
                x: -w / 2 + 10,
                y: -h / 2 + 10,
              },
            });
            //公司名称
            if (cfg.name) {
              const max = 14;
              const isLineFeed = cfg.name?.length > max;
              const name = cfg.name; //isLineFeed? cfg.name?.slice(0, max) + "\n" + cfg.name?.slice(max): cfg.name;
              const len = isLineFeed ? max : cfg.name?.length;
              rectGroup.addShape("text", {
                draggable: true,
                attrs: {
                  fill: textColor,
                  fontSize: 14,
                  cursor: "pointer",
                  text: name,
                  align: "center",
                  x: -w / 2 + 20,
                  y: h / 2 + 2,
                },
              });
            }
          } else {
            //图像logo
            rectGroup.addShape("circle", {
              draggable: true,
              attrs: {
                name: "image-circle",
                x: 0,
                y: h / 2 - 5,
                r: 24,
                fill: fillColor,
                stroke: color,
                cursor: "pointer",
              },
              name: "image-circle",
            });
            let x = 0;
            if (cfg.name.length === 2) {
              x = -12;
            } else if (cfg.name.length === 3) {
              x = -17;
            }
            rectGroup.addShape("text", {
              draggable: true,
              attrs: {
                fill: textColor,
                fontSize: 12,
                text: cfg.name,
                align: "center",
                cursor: "pointer",
                x: x,
                y: h / 2,
              },
            });
          }
          return keyShape;
        },
        setState(name, value, item) {
          const group = item.get("group");
          if (name === "noHover") {
            const rect = group.find((e) => e.get("name") === "rect-box");
            rect?.attr({
              opacity: 1,
            });
          }
          if (name === "hover") {
            const rect = group.find((e) => e.get("name") === "rect-box");
            rect?.attr({
              opacity: value ? 1 : 0.2,
            });
          }
        },
      },
      "rect"
    );
    if (!isDownLoad) {
      graph.current = new G6.Graph({
        container: contentId.current,
        width,
        height,
        fitView: true,
        modes: {
          default: ["zoom-canvas", "drag-canvas", "drag-node"],
        },
        layout: {
          type: "fruchterman",
          gpuEnabled: false,
          // maxIteration: 1000,
          gravity: 0.2,
          clusterGravity: 1,
        },
        defaultNode: {
          type: "path-node",
          size: [200, 40],
          style: defaultNodeStyle,
          labelCfg: defaultLabelCfg,
        },
        defaultEdge: {
          type: "chart-line",
          style: defaultEdgeStyle,
        },
      });
      setBgShow(true);
      graph.current.data(data);
      graph.current.render();
      if (getSearchCompany()) {
        graph.current.fitView(20);
      }
      graph.current.on("afterlayout", (evt) => {
        // 一些操作
        setBgShow(false);
      });
      // graph.current.layout();
      graph.current.on("rect-box:mouseenter", (evt) => {
        const { item } = evt;
        if (timer2.current) {
          clearTimeout(timer2.current);
        }
        timer2.current = setTimeout(() => {
          nodeActiveFunc(item, true);
        }, 200);
      });

      graph.current.on("rect-box:mouseleave", (evt) => {
        const { item } = evt;
        if (timer2.current) {
          clearTimeout(timer2.current);
        }
        timer2.current = setTimeout(() => {
          nodeActiveFunc(item, false);
        }, 200);
      });

      graph.current.on("node:click", (evt) => {
        const { item, target } = evt;
        const model = item.getModel();
        const _item = {
          shareholderId: { val: model.entity_id },
          name: { val: model.name },
          s_type: { val: model._label === "Person" ? 2 : 1 },
          click_all: true,
        };
        showInfoFunc(_item, _item, "主要股东", id, "name");
      });
      if (typeof window !== "undefined")
        window.onresize = () => {
          if (!graph.current || graph.current?.get("destroyed")) return;
          const container = document.getElementById(contentId.current);
          if (!container || !container.clientWidth || !container.clientHeight)
            return;
          graph.current.changeSize(
            container.clientWidth,
            container.clientHeight
          );
        };
    } else {
      downloadGraph.current = new G6.Graph({
        container: "downloadCanvasBox",
        width,
        height,
        fitView: true,
        modes: {
          default: ["zoom-canvas", "drag-canvas", "drag-node"],
        },
        layout: {
          type: "fruchterman",
          gpuEnabled: true,
          maxIteration: 1000,
          gravity: 0.2,
          clusterGravity: 1,
        },
        defaultNode: {
          type: "path-node",
          size: [200, 40],
          style: defaultNodeStyle,
          labelCfg: defaultLabelCfg,
        },
        defaultEdge: {
          type: "chart-line",
          style: defaultEdgeStyle,
        },
      });
      downloadGraph.current.data(data);
      downloadGraph.current.render();
      // downloadGraph.current.zoomTo(2);
    }
  };

  //获取所有公司名称
  const initCompanyList = (list) => {
    let companyList = [];
    list.forEach((_) => {
      _.forEach((__) => {
        //只取第一条路径的公司
        const path = __?.path?.at(0);
        path?.forEach((___) => {
          const [a, b, c] = ___ || [];
          if (a.s_type) {
            companyList.push(a?.property?.name + "~~" + a?.property?.entity_id);
          }
          if (c.s_type) {
            companyList.push(c?.property?.name + "~~" + c?.property?.entity_id);
          }
        });
      });
    });
    companyList = [...new Set(companyList)];
    companyList = companyList.sort((a, b) => a.localeCompare(b));
    setCompanyOptions(companyList);
  };

  //根据公司名称 筛选图标数据
  const initPathsGroupFunc = (list, companyName) => {
    let pathsGroup = [];
    if (companyName) {
      const [name, id] = companyName?.split("~~");
      pathsGroup = list
        .map((_) => {
          _ = _.map((__) => {
            let isHas = false;
            const path = __?.path?.at(0) || [];
            for (let index = 0; index < path?.length; index++) {
              //只取第一条路径
              let [a, b, c] = path[index];
              if (
                (a?.property?.entity_id === Number(id) &&
                  a?.property?.name === name) ||
                (c?.property?.entity_id === Number(id) &&
                  c?.property?.name === name)
              ) {
                isHas = true;
              }
              if (isHas) {
                break;
              }
            }

            __.isHas = isHas;
            return __;
          })?.filter((_) => _.isHas);
          return _;
        })
        .filter((_) => _?.length > 0);
    } else {
      pathsGroup = list;
    }
    setPathsGroup(pathsGroup);
  };

  useEffect(() => {
    setPath(pathsGroup?.at(0) || []);
    setActiveIndex(0);
    initPath(pathsGroup?.at(0) || [], addInformation);
  }, [pathsGroup]);

  useEffect(() => {
    initPathsGroupFunc(chartData);
    initCompanyList(chartData);
  }, [chartData]);

  //下载图表
  const downloadChart = async () => {
    await initChart(
      {
        nodes: nodes,
        edges: edges,
      },
      true,
      addInformation
    );
    // window.oldRatio = window.devicePixelRatio;
    // window.devicePixelRatio = 2;
    const pngName =
      projectInfo.company_name +
      "疑似关系核查结果图谱_" +
      moment(checkDate).format("YYYY-MM-DD HH_mm_ss");
    await downloadGraph.current.downloadFullImage(pngName, "image/png", {
      backgroundColor: "#fff",
      padding: [15, 15, 15, 15],
    });
    // await setTimeout(() => {
    //   window.devicePixelRatio = window.oldRatio;
    // }, 100);
    await downloadGraph.current.destroy();
    downloadGraph.current = null;
    projectApi.setOptLog(id, {
      event_type: "CDD_CONCERN_SUSPECTED_RELATED_PARTY",
      content: pngName + ".png",
    });
  };

  const handleChange = (index) => {
    setPath(pathsGroup?.at(index) || []);
    setActiveIndex(index);
    initPath(pathsGroup?.at(index) || [], addInformation);
  };
  const loadOptions = (type) => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      let index = activeIndex;
      let len = pathsGroup?.length;
      if (type === "next") {
        index++;
        index = index >= len ? len - 1 : index;
      } else {
        index--;
        index = index < 0 ? 0 : index;
      }
      handleChange(index);
    }, 500);
  };

  return (
    <div
      id={contentId.current}
      className="pathChartContent"
      style={{ width: "100%", height: "100%", position: "relative" }}
    >
      <div className="pathChartIconBox">
        <VerticalAlignBottomOutlined
          onClick={() => {
            downloadChart();
          }}
        />
        <Checkbox
          checked={addInformation}
          onChange={(e) => {
            setAddInformation(e.target.checked);
            initPath(pathsGroup?.at(activeIndex) || [], e.target.checked);
          }}
        >
          是否要附带信息
        </Checkbox>
      </div>
      <div className="pathChartSearchBox">
        <Select
          value={searchCompany}
          onChange={(value) => {
            setSearchCompany(value);
            initPathsGroupFunc(chartData, value);
          }}
          showSearch
          placeholder="请输入企业名称搜索"
          style={{ marginRight: "5px", width: "240px" }}
          options={companyOptions?.map((_) => ({
            label: _.split("~~")?.at(0),
            value: _,
          }))}
        />
        {searchCompany && (
          <Button
            type="primary"
            onClick={() => {
              setSearchCompany(null);
              initPathsGroupFunc(chartData);
            }}
          >
            重置
          </Button>
        )}
      </div>
      <div className="sTypeColorBox">
        {Object.keys(sTypeColorMap).map((key) => (
          <div
            key={key}
            className="sTypeItem"
            style={{ background: sTypeColorMap[key] }}
          >
            {key}
          </div>
        ))}
      </div>

      {pathsGroup?.length > 1 && (
        <div className="btnListBox">
          {pathsGroup.map((item, index) => (
            <div
              className="btnItem"
              key={index}
              style={{
                background: index === activeIndex ? "#0068B2" : "#D8D8D8",
              }}
              onClick={() => {
                handleChange(index);
              }}
            ></div>
          ))}
        </div>
      )}
      {pathsGroup?.length > 1 && (
        <RightOutlined
          className={
            activeIndex === pathsGroup?.length - 1
              ? "icon rightIcon disableIcon"
              : "icon rightIcon"
          }
          onClick={() => {
            activeIndex !== pathsGroup?.length - 1 && loadOptions("next");
          }}
        />
      )}
      {pathsGroup?.length > 1 && (
        <LeftOutlined
          className={
            activeIndex === 0 ? "icon leftIcon disableIcon" : "icon leftIcon"
          }
          onClick={() => {
            activeIndex !== 0 && loadOptions("back");
          }}
        />
      )}
      <div id="downloadCanvasBox"></div>
      <div
        className="canvasLoadingBg"
        style={{ display: bgShow ? "flex" : "none" }}
      >
        <MySpin></MySpin>
      </div>
    </div>
  );
};
export default Chart;
