import { Tag } from "antd";

const StatusText = (props) => {
  const { status } = props;
  switch (status) {
    case "尽调中":
      return <div style={{ color: "#61D4AD" }}>尽调中</div>;
    case "尽调完成":
      return <div style={{ color: "#61D4AD" }}>尽调完成</div>;
    case "尽调失败":
      return <div style={{ color: "#ff4d4f" }}>尽调失败</div>;
    case "关闭":
      return <div style={{ color: "#535353" }}>关闭</div>;
    default:
      return <Tag>关闭</Tag>;
  }
};
export default StatusText;
