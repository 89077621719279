import "./index.scss";
import { useState, useEffect, useRef } from "react";
import { Typography, Avatar, Tooltip, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import MessageModal from "../../../../components/MessageModal";
import bondApi from "../../../../apis/bond";
import * as echarts from "echarts";
import img from "../../../../assets/empChart.png";
import { outerCircleMap, innerCircleMap } from "../../../../setting";
import moment from "moment";
import noImg from "../../../../assets/noImg.jpeg";
import { ReactSVG } from "react-svg";
import deleteIcon from "../../../../assets/delete2.svg";
import { useUnmount } from 'ahooks';

const { Paragraph } = Typography;

const CircleBar = (props) => {
  const { data, id } = props;
  const myChart = useRef(null);

  useUnmount(() => {
    const dom = document.documentElement.querySelector(`.${id}_tooltip`);
    if(dom){
      document.documentElement.removeChild(dom)
    }
  });

  const initEcharts = () => {
    if (myChart.current) {
      myChart.current.dispose();
      myChart.current = null;
    }
    // 基于准备好的dom，初始化echarts实例
    const dom = document.getElementById(id);
    if (!dom) {
      return;
    }

    const chartData = data?.map((_, index) => {
      return {
        value: _.count || 0,
        name: _.event_code_name || "",
        color: outerCircleMap[index],
        itemStyle: {
          color: outerCircleMap[index],
        },
      };
    });
    myChart.current = echarts.init(dom);

    let option = {
      tooltip: {
        trigger: "item",
        position: "left",
        // appendToBody: true,
        className: id + '_tooltip',
        appendTo: document.documentElement,
        formatter: (info) => {
          let str = `<div style='min-width:150px'><div style='font-weight:bold;width:100%'>新增重要动态</div>`;
          chartData.forEach((_) => {
            str += `<div style='width:100%;display:flex;justify-content:space-between;'>
            <span style="font-weight:${
              info?.name === _?.name ? "bold" : "unset"
            };color:${info?.name === _?.name ? _.color : "unset"};">
            <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${
              _.color
            };"></span>
            ${_.name}</span>
            <span style='font-weight:bold;color:${
              info?.name === _?.name ? _.color : "unset"
            }'>${_.value}条</span>
            </div>`;
          });
          return str + `</div>`;
        },
      },
      grid: {
        left: "0%", // 可以设置为百分比或者固定的像素值
        right: "0%",
        bottom: "0%",
        top: "0%",
        containLabel: true, // 确保标签也在这个范围内
        padding: [0, 0, 0, 0], // 设置所有方向的内边距为0
      },
      series: [
        {
          type: "pie",
          radius: "85%",
          data: chartData,
          labelLine: {
            show: false,
          },
        },
      ],
    };

    myChart.current.setOption(option);
    window.addEventListener("resize", () => {
      myChart.current && myChart.current.resize();
    });
  };

  useEffect(() => {
    initEcharts();
  }, [data]);
  return <div id={id} className="circleBar"></div>;
};

const LineBar = (props) => {
  const { data, id } = props;
  const myChart = useRef(null);

  useUnmount(() => {
    const dom = document.documentElement.querySelector(`.${id}_tooltip`);
    if(dom){
      document.documentElement.removeChild(dom)
    }
  });

  const initEcharts = () => {
    if (myChart.current) {
      myChart.current.dispose();
      myChart.current = null;
    }
    // 基于准备好的dom，初始化echarts实例
    const dom = document.getElementById(id);
    if (!dom) {
      return;
    }

    const chartData = {};

    [0, 1, 2, 3, 4, 5, 6].forEach((_) => {
      let dayKey = moment(new Date()).subtract(_, "days").format("YYYY-MM-DD");
      chartData[dayKey] = 0;
    });

    data?.forEach((_, index) => {
      if (chartData[_.time]) {
        chartData[_.time] = chartData[_.time] + _.count;
      } else {
        chartData[_.time] = _.count || 0;
      }
    });

    myChart.current = echarts.init(dom);
    let len = Object.keys(chartData)?.length;

    const _chartData = Object.keys(chartData)
      ?.sort((a, b) => new Date(a)?.getTime() - new Date(b)?.getTime())
      .map((key, index) => ({
        value: chartData[key],
        name: key,
        color: innerCircleMap[len - (index + 1)],
        itemStyle: {
          color: innerCircleMap[len - (index + 1)],
          barBorderRadius: [4, 4, 0, 0],
        },
      }));
    let option = {
      tooltip: {
        trigger: "item",
        position: "left",
        className: id + '_tooltip',
        appendTo: document.documentElement,
        // appendToBody: true,
        formatter: (info) => {
          let str = `<div style='min-width:150px'><div style='font-weight:bold;width:100%'>新增重要动态</div>`;
          _chartData.forEach((_, index) => {
            str += `<div style='width:100%;display:flex;justify-content:space-between;'>
            <span style='font-weight:${
              info?.name === _?.name ? "bold" : "unset"
            };color:${info?.name === _?.name ? _.color : "unset"}'>
            <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${
              _.color
            };"></span>
            ${moment(_?.name).format("MM-DD")}</span>
            <span style='font-weight:bold;color:${
              info?.name === _?.name ? _.color : "unset"
            }'>${_?.value}条</span>
            </div>`;
          });
          return str + `</div>`;
        },
      },
      grid: {
        left: "0%", // 可以设置为百分比或者固定的像素值
        right: "0%",
        bottom: "2px",
        top: "0%",
        containLabel: true, // 确保标签也在这个范围内
        padding: [0, 0, 0, 0], // 设置所有方向的内边距为0
      },
      xAxis: {
        type: "category",
        data: Object.keys(chartData),
        axisPointer: {
          type: "shadow",
        },
        // axisLabel: {
        //   interval: 0,
        //   hideOverlap: true,
        //   show: false,
        //   // rotate: -15,
        // },
        // axisTick: {
        //   alignWithLabel: true,
        //   interval: 0,
        // },
        // boundaryGap: false, // 坐标轴两边留白策略
        axisLine: {
          // 坐标轴轴线
          show: false,
        },
        axisTick: {
          // 坐标轴刻度
          show: false,
        },
        splitLine: {
          // 坐标轴在 grid 区域中的分隔线。
          show: false,
        },
        axisLabel: {
          // 坐标轴刻度标签
          show: false,
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          show: false,
        },

        splitLine: {
          // 坐标轴在 grid 区域中的分隔线
          lineStyle: {
            // 分隔线
            type: "dashed", // 线的类型
            color: "#CCCCCC", // 分隔线颜色
          },
        },
      },
      series: [
        {
          data: _chartData,
          barWidth: 10,
          type: "bar",
        },
      ],
    };

    myChart.current.setOption(option);
    window.addEventListener("resize", () => {
      myChart.current && myChart.current.resize();
    });
  };

  useEffect(() => {
    initEcharts();
  }, [data]);

  return <div id={id} className="lineBar"></div>;
};

const BondCard = (props) => {
  const { info = {}, deleteCallBack } = props;
  const [picUrl, setPicUrl] = useState(info?.logo_url);

  const columns = useRef([
    {
      label: "统一信用号",
      key: "credit_code",
      width: "225px",
      type: "text",
      format: (data, info) => {
        return (
          <Paragraph
            style={{
              width: `100%`,
              color: "#535353",
            }}
            ellipsis={{
              tooltip: (
                <span style={{ fontSize: "12px" }}>{info?.credit_code}</span>
              ),
            }}
          >
            {info?.credit_code}
          </Paragraph>
        );
      },
    },
    {
      label: "法人",
      key: "legal_person_name",
      width: "calc(100% - 225px)",
      type: "text",
      format: (data, info) => {
        return (
          <Paragraph
            style={{
              width: `100%`,
              color: "#535353",
            }}
            ellipsis={{
              tooltip: info?.legal_person_name,
            }}
          >
            {info?.legal_person_name}
          </Paragraph>
        );
      },
    },
    {
      label: "债券",
      key: "s_info_names",
      width: "100%",
      type: "text",
      format: (data, info) => {
        return (
          <Paragraph
            style={{
              width: `100%`,
              color: "#535353",
            }}
            ellipsis={{
              tooltip: {
                title: (
                  <div className="bondAddPageParagraphTip">
                    {info?.s_info_names}
                  </div>
                ),
                overlayClassName: "bondAddPageParagraphTooltip",
              },
            }}
          >
            {info?.s_info_names}
          </Paragraph>
        );
      },
    },
  ]);

  const initValue = (type, key, format) => {
    if (type === "text") {
      if (format) {
        return format(info[key], info);
      }
      return info[key] || "";
    }
  };

  const goInfo = (item) => {
    let url = window.location.origin + "/bond/" + item.company_id;
    window.open(url, item.company_id);
  };

  const deleteItem = () => {
    MessageModal({
      type: "warning",
      description: "确定要取消关注吗？",
      title: "取消关注",
      okText: "取消关注",
      onOk: () => {
        bondApi.deleteIssuers(info.company_id).then((res) => {
          if (res.code === 200) {
            deleteCallBack && deleteCallBack();
            message.success(res.message);
          } else {
            message.error(res.message);
          }
        });
      },
    });
  };

  return (
    <div className="bondCardContent">
      <div className="cardInfoBox">
        <div className="cardBox1">
          <div className="iconBox">
            {picUrl ? (
              <img
                src={picUrl}
                className="infoImg"
                onError={(e) => {
                  setPicUrl(noImg);
                }}
                onClick={() => {
                  // imgClick && imgClick(cardData);
                  goInfo(info);
                }}
              ></img>
            ) : (
              <Avatar
                className="hoverPic"
                size={66}
                shape="square"
                onClick={() => {
                  // imgClick && imgClick(cardData);
                  goInfo(info);
                }}
              >
                {info?.company_name?.length >= 6
                  ? info?.company_name?.substring(0, 6)
                  : info?.company_name}
              </Avatar>
            )}
          </div>
          <div className="infoBox">
            <div
              className="titleItem"
              onClick={() => {
                goInfo(info);
              }}
            >
              <Paragraph
                style={{
                  width: `100%`,
                  color: "#535353",
                }}
                ellipsis={{
                  tooltip: <span>{info.company_name}</span>,
                }}
              >
                {info.company_name}
              </Paragraph>
            </div>
            <div className="itemBox">
              {(columns?.current || []).map((item) => (
                <div
                  className="valueItem"
                  key={item.key}
                  style={{ width: item.width || "100%", ...(item.style || {}) }}
                >
                  <div className="label" style={{ ...(item.style || {}) }}>
                    {item.label}
                  </div>
                  {item.render ? (
                    item.render(info[item.key], info)
                  ) : (
                    <div className="value">
                      {initValue(item.type, item.key, item.format)}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="numberBox">
          <div>近期 新增 重要动态</div>
          <div className="numberDiv">
            <span
              style={{
                color: info.event_total_count || 0 ? "#0068B2" : "#535353",
              }}
            >
              {info.event_total_count || 0}
            </span>
            条
          </div>
        </div>
        <div className="lineBarBox">
          {info?.event_time_count?.length > 0 ? (
            <LineBar
              data={info?.event_time_count}
              id={"lineBar" + info.company_id}
            ></LineBar>
          ) : (
            <div className="noDataChartBox">
              {/* <img src={img} draggable={false}></img> */}
              暂无数据
            </div>
          )}
        </div>
        <div className="cardBox2">
          <div className="circleBarBox">
            {info?.event_count?.length > 0 ? (
              <CircleBar
                data={info?.event_count}
                id={"circleBar" + info.company_id}
              ></CircleBar>
            ) : (
              <div className="noDataChartBox">
                {/* <img src={img} draggable={false}></img> */}
                暂无数据
              </div>
            )}
          </div>
          <div className="deleteIconBox">
            <Tooltip placement="top" title="取消关注" arrowPointAtCenter>
              {/* <DeleteOutlined
                onClick={() => {
                  deleteItem();
                }}
              /> */}
              <ReactSVG
                onClick={(e) => {
                  e.stopPropagation();
                  deleteItem();
                }}
                className="deleteIcon hoverIcon"
                src={deleteIcon}
              ></ReactSVG>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BondCard;
