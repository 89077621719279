import "./index.scss";
import Scrollbars from "react-custom-scrollbars";
import HeaderTitle from "../../components/HeaderTitle";
import {
  Tabs,
  Card,
  Tooltip,
  message,
  Tag,
  Typography,
  Empty,
  Button,
} from "antd";
import {
  StarOutlined,
  StarFilled,
  FolderOpenOutlined,
} from "@ant-design/icons";
import { useEffect, useState, useRef } from "react";
import MySpin from "../../components/MySpin";
import ProjectApi from "../../apis/project/index";
import StatusTag from "../../components/StatusTag";
import StatusText from "../../components/StatusText";
import { useNavigate } from "react-router-dom";
import { useGetState, useUnmount, useUnmountedRef } from "ahooks";
import emptyPng from "../../assets/empty11.png";
const { Paragraph } = Typography;

const { TabPane } = Tabs;

const MyCard = (props) => {
  const { info, collectHandle, index, projectStatus } = props;
  const labelList = [
    {
      label: "尽调对象",
      value: (
        <>
          <span
            style={{ marginRight: info.is_listed_company === 1 ? "8px" : "0" }}
          >
            {info["company_name"]}
          </span>
          {info.is_listed_company === 1 && info.stock_block && (
            <Tag className="companyTypeTag">{info.stock_block}</Tag>
          )}
          {info.is_listed_company === 1 && info.stock_code && (
            <Tag className="companyTypeTag">{info.stock_code}</Tag>
          )}
        </>
      ),
    },
    {
      label: "项目状态",
      value: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <StatusText status={info.status_name}></StatusText>
          <StatusTag status={projectStatus}></StatusTag>
        </div>
      ),
    },
  ];

  const getInfo = () => {
    let url = window.location.origin + "/company/" + info.id;
    window.open(url, "_blank");
  };

  return (
    <Card
      style={{ marginRight: index % 3 === 2 ? "0px" : "22.5px" }}
      className="cardItem"
      onClick={() => {
        getInfo();
      }}
    >
      <div className="cardContent">
        <div className="cardTitle">
          <Paragraph
            ellipsis={{
              tooltip: {
                title: (
                  <div className="tableColumnsParagraphTip">
                    {info.project_name}
                  </div>
                ),
                overlayClassName: "tableColumnsParagraphTooltip",
              },
              rows: 2,
              expandable: false,
            }}
            style={{
              width: `calc(100% - ${
                info?.project_permission_urls?.includes("CDD_PRJ_FAV") ? 30 : 0
              }px)`,
              marginBottom: "0px",
              fontSize: "16px",
              fontWeight: "500",
              color: "#313131",
            }}
          >
            {info.project_name}
          </Paragraph>

          {info?.project_permission_urls?.includes("CDD_PRJ_FAV") &&
          info.status_name !== "关闭" ? (
            info.is_star_mark ? (
              <Tooltip placement="top" title="取消关注" arrowPointAtCenter>
                <StarFilled
                  style={{ color: "#FEC054" }}
                  className="hoverIcon"
                  onClick={(e) => {
                    e.stopPropagation();
                    collectHandle(false, info.id);
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip placement="top" title="关注" arrowPointAtCenter>
                <StarOutlined
                  className="hoverIcon"
                  onClick={(e) => {
                    e.stopPropagation();
                    collectHandle(true, info?.id);
                  }}
                />
              </Tooltip>
            )
          ) : null}
        </div>
        <div style={{ width: "100%" }}>
          {labelList.map((item) => (
            <div className="cardLabel" key={item?.label}>
              <div className="label">{item?.label}</div>
              <div className="value">{item?.value}</div>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};

const MyProjects = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeKey, setActiveKey, getActiveKey] = useGetState(props.defaultKey);
  const tabs = ["我的关注", "最近查看"];
  const navigate = useNavigate();

  const [projectsStatusObj, setProjectsStatusObj] = useState({});
  const timer = useRef();
  const unmountedRef = useUnmountedRef();

  useUnmount(() => {
    clearTimeout(timer.current);
  });

  useEffect(() => {
    getData(activeKey);
  }, []);

  /* 获取数据列表 */
  const getData = (key) => {
    setLoading(true);
    let params = {};
    if (key === "我的关注") {
      params.is_star_mark = 1;
    }
    if (key === "最近查看") {
      params.is_recently_viewed = 1;
    }
    ProjectApi.getProjectList(params).then((res) => {
      if (res.code === 200) {
        if (key === getActiveKey()) {
          setData(res.data.data);
        }
      } else {
        message.error(res.message);
      }
      setLoading(false);
    });
  };

  /* 类型发生变化 */
  const tabChange = (key) => {
    setActiveKey(key);
    getData(key);
    props.setActiveKey && props.setActiveKey(key);
  };

  const collectHandle = (isCollection, id) => {
    ProjectApi.changeProjectStar(id, isCollection ? 1 : 0).then((res) => {
      if (res.code === 200) {
        setData((prev) => {
          prev = prev.map((item) => {
            if (item.id == id) {
              item.is_star_mark = isCollection ? 1 : 0;
            }
            return item;
          });
          if (activeKey === "我的关注" && isCollection === false) {
            prev = prev.filter((item) => item.id !== id);
          }
          return [...prev];
        });
      } else {
        message.error(res.message);
      }
    });
  };

  const getProjectsStatus = () => {
    ProjectApi.getProjectStatus().then((res) => {
      console.log("项目排队情况", res);
      if (res.code === 200) {
        setProjectsStatusObj(res.data || {});
        if (Object.keys(res?.data || {}).length > 0) {
          if (timer.current) {
            clearTimeout(timer.current);
          }
          if (unmountedRef.current) {
            console.log("组件已经卸载");
            return;
          }
          timer.current = setTimeout(() => {
            getProjectsStatus();
          }, 2.5 * 1000);
        }
      } else {
        setProjectsStatusObj({});
        message.error(res.message);
      }
    });
  };
  useEffect(() => {
    getProjectsStatus();
  }, []);

  const initProjectStatus = (status, id) => {
    return projectsStatusObj[id] || "";
  };
  return (
    <div className="myProjectsContent">
      <div style={{ position: "relative" }}>
        <HeaderTitle
          title="我的项目"
          extra={
            <div
              className="iconBox"
              onClick={() => navigate("/projectManagement")}
            >
              <FolderOpenOutlined />
              全部项目
            </div>
          }
        ></HeaderTitle>
        <div className="headerTabPaneBox">
          {tabs.map((key) => (
            <div
              key={key}
              className={
                key === activeKey
                  ? "headerTabPane activeHeaderTabPane"
                  : "headerTabPane"
              }
              onClick={() => {
                tabChange(key);
              }}
            >
              {key}
            </div>
          ))}
        </div>
      </div>

      <div style={{ height: "calc(100% - 49px)", marginTop: "24px" }}>
        <Scrollbars
          autoHide={true}
          autoHideTimeout={5000}
          style={{ height: "100%", width: "100%" }}
        >
          {loading ? (
            <MySpin></MySpin>
          ) : data?.length > 0 ? (
            <div className="cardBox">
              {data.map((item, index) => (
                <MyCard
                  index={index}
                  info={item}
                  key={item.id}
                  projectStatus={initProjectStatus(item.status_name, item.id)}
                  collectHandle={collectHandle}
                ></MyCard>
              ))}
            </div>
          ) : (
            <div className="noData">
              <Empty
                image={emptyPng}
                imageStyle={{
                  height: 120,
                }}
                description={
                  <span style={{color: '#9E9E9E'}}>
                    您暂未{activeKey === '我的关注' ? '关注' : '查看过'}项目
                    <br />
                    是否立即前往项目管理中查看全部项目?
                  </span>
                }
              >
                <Button onClick={()=>{navigate("/projectManagement")}} type="primary">查看全部项目</Button>
              </Empty>
            </div>
          )}
        </Scrollbars>
      </div>
    </div>
  );
};

export default MyProjects;
